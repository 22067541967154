import {LIST_AREAS,AREA_STATUS_CREATE,UPDATE_AREA,GET_AREA,ACTIVE_AREA,
   DELETE_AREA,AREA_STATUS_ACTIONS,AREA_LOADING, ADD_AREA, AREA_VISIBLE,LIST_AREAS_SELECT} from "../ActionTypes";

const INIT_STATE = {
  areasList: [],
  areaListSelect:[],
  areaObject: [],
  areaStatusActions: 0,
  loading:false,
  areaVisible:false,
  areaStatusCreate: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case LIST_AREAS: {
      state.areasList.some(e=> e.id == action.payload.id) && state.areasList.splice(state.areasList.findIndex(c=>c.id==action.payload.id),1)
      return {
        ...state,
        areasList: state.areasList.concat({"id":action.payload.id,"data":action.payload.data}),
      };
    }

    case LIST_AREAS_SELECT: {
      return {
        ...state,
        areaListSelect: action.payload,
      };
    }

    case AREA_STATUS_CREATE: {
      return {
          ...state,
          areaStatusCreate:action.payload
      }
    }

    case GET_AREA: {
      return {
        ...state,
        areaObject: action.payload,
      };
    }

    case ACTIVE_AREA: {
        return {
            ...state
        }
    }   

    case DELETE_AREA: {
        return {
            ...state
        }
    }

    case AREA_STATUS_ACTIONS: {
      return {
          ...state,
          areaStatusActions:action.payload
      }
    }

    case AREA_LOADING: {
      return {
      ...state,
      loading: action.payload,
      }
   }

   case ADD_AREA: {
      return {
          ...state
      }
    }

    case AREA_VISIBLE: {
      return {
      ...state,
      areaVisible: action.payload,
      }
    }

    case UPDATE_AREA: {
      return {
          ...state
      }
  }

    default:
      return state;
  }
}
