import {
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  SHOW_MESSAGE,
  REGISTER_LOADING,
  REGISTER_VISIBLE,
  REGISTER_STATUS_CREATE,
  REGISTER_STATUS_ACTIONS,
  ADD_REGISTER,
  REGISTER_USER_NAME,
  REGISTER_MESSAGE
  } from "../ActionTypes";
  import axios from '../../util/Api'
  
   

  export const addRegister = (registerObject) => {
    
    let config = {
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        }
    }
    
    return (dispatch) => {
        dispatch({type: REGISTER_LOADING,payload:true});
        dispatch({type: FETCH_START});
        axios.post('/external/clients',
        registerObject,
            config
        ).then(({data,status}) => {
            dispatch({type: REGISTER_LOADING,payload:false});

            if (data) {
                dispatch({type: FETCH_SUCCESS});

                dispatch({type: ADD_REGISTER});
                dispatch({type: SHOW_MESSAGE, payload: data.message});

                dispatch({type: REGISTER_USER_NAME, payload: data.userName});

                dispatch({type: REGISTER_VISIBLE,payload:false});
                 dispatch({type: REGISTER_STATUS_ACTIONS, payload: status});

                
            } else {
                dispatch({type: FETCH_ERROR, payload: data.message});
            }

        })
        .catch(function (error) {

            dispatch({type: REGISTER_LOADING,payload:false});
            dispatch({type: FETCH_ERROR, payload: error.response.data.message});
            
        });
    }
};
