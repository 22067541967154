import React, { useState, useEffect } from 'react';
import logo from "../logo_pacifico.png"
import "../styles/activate.scss"
import { useLocation } from "react-router-dom";
import { CheckCircleOutlined } from '@ant-design/icons';

import 'antd/dist/antd.css'
import { connect, useDispatch, useSelector } from "react-redux";
import { Row, Col, Form, Card, Input, message, Button } from 'antd'
import { getSettings, activePassword } from "../appRedux/actions/Auth";

import { activate } from "../appRedux/actions/Activate";

const Activate = (props) => {

    const query = new URLSearchParams(useLocation().search);
    const code = query.get('code');
    const activateStatusActions = useSelector(store => store.activate.activateStatusActions)
    const authStatusActions = useSelector(store => store.auth.authStatusActions)

    const activate_message = useSelector(store => store.activate.activate_message)
    const auth_message = useSelector(store => store.auth.auth_message)


    const dispatch = useDispatch()
    const [password1, setPassword1] = useState('');
    const [password2, setPassword2] = useState('');
    let [show, setShow] = useState('1');
    let [showAuth, setShowAuth] = useState('');


    ///////////////////////////////



    const [minLength, setMinLength] = useState(false)
    const [maxLength, setMaxLength] = useState(false)
    const [capitalLetters, setCapitalLetters] = useState(false)
    const [lowLetters, setLowLetters] = useState(false)
    const [number, setNumber] = useState(false)
    const [specialChar, setSpecialChar] = useState(false)

    const password_requirements = useSelector(({ auth }) => auth.password_requirements);



    /////////////////////////////////////////
    useEffect(() => {

        if (password_requirements.length == 0) {
            dispatch(getSettings());
        }

        if (code != ''&&code!=null) {
            if (activateStatusActions == 0) {
                dispatch(activate(code))

            } else
                if (activateStatusActions == 201) {
                    setShow('2')

                } else {
                     setShow('3')

                }

            //AL REGISTRAR CONTRASEÑA     
            if (authStatusActions != 0) {

                if (authStatusActions != 200) {
                    setShowAuth('1')

                } else {

                    return window.location = '/home';

                }
            }





        } else {
            setShow('4')
        }
    });




    function handleChangePassword(event) {
        setPassword1(event.target.value);

        console.log(event.target.value)
        if (event.target.value != undefined) {
            event.target.value.length >= password_requirements.MINIMUN_PASSWORD_LENGTH.value ? setMinLength(true) : setMinLength(false);
            event.target.value.length > 0 && event.target.value.length <= password_requirements.MAXIMUM_PASSWORD_LENGTH.value ? setMaxLength(true) : setMaxLength(false);
            (/[a-z]/).test(event.target.value) ? setLowLetters(true) : setLowLetters(false);
            (/[A-Z]/).test(event.target.value) ? setCapitalLetters(true) : setCapitalLetters(false);
            (/\d/).test(event.target.value) ? setNumber(true) : setNumber(false);
            (/[^a-zA-Z\d]/).test(event.target.value) ? setSpecialChar(true) : setSpecialChar(false);
        }



    }
    function handleChangePassword2(event) {
        setPassword2(event.target.value)

    }

    function handleOnSave() {

        if (password2 != password1) {
            message.error('Las contraseñas deben ser iguales')
            return false;
        }else 

        if (minLength == false || maxLength == false || (capitalLetters == false && password_requirements.REQUIRE_CAPITAL_LETTERS.value == 1) || (lowLetters == false && password_requirements.REQUIRE_LOWER_LETTERS.value == 1) || (number == false && password_requirements.REQUIRE_NUMBER.value == 1 ) || (specialChar == false && password_requirements.REQUIRE_CARACTER_ESPECIAL.value == 1)) {
            message.error('La contraseña debe cumplir los parametros establecidos')
            return false;
        }else{

            let object = { password: password1, password_rep: password2 }

            props.activePassword(object);
        }


      
       


    }


    return (
        <div class='body'>
            <Row style={{ justifyContent: 'center' }}>
                <img width="200" src={logo} />
            </Row>
            {show == '2' ? (
                <Row style={{ justifyContent: 'center' }} >
                    <Card className='card-activate'>
                        <div class="" style={{ textAlign: 'center', fontSize: '1.5rem' }} ><b>!Activación exitosa¡</b></div>

                        <div style={{ textAlign: 'center', fontSize: '0.8rem' }} >Ahora ingrese una contraseña para acceder a tu cuenta   </div>
                        <br></br>
                        <Row gutter={16} >
                            <Col className="gutter-row" xs={24} lg={24} >
                                <Input.Password style={{ borderRadius: '20px', margin: '5px' }} size="small" value={password1} onChange={handleChangePassword.bind(this)} placeholder='Contraseña' />

                            </Col>


                            <Col className="gutter-row" xs={24} lg={24} >
                                <Input.Password style={{ borderRadius: '20px', margin: '5px' }} size="small" value={password2} onChange={handleChangePassword2} placeholder='Repita su contraseña' />

                            </Col>
                            <Col className="gutter-row" xs={24} lg={24} >
                                <br></br>

                                {
                                    typeof (password_requirements) == 'object' && password_requirements.hasOwnProperty('MINIMUN_PASSWORD_LENGTH') ?
                                        <PasswordRequirements
                                            minLength={minLength}
                                            maxLength={maxLength}
                                            capitalLetters={capitalLetters}
                                            lowLetters={lowLetters}
                                            number={number}
                                            specialChar={specialChar} password_requirements={password_requirements}></PasswordRequirements>
                                        : ''

                                }

                            </Col>

                        </Row>


                        <br></br>
                        <Row style={{ justifyContent: 'center' }}>


                            <Button onClick={handleOnSave} className='btn-success'>Actualizar</Button>



                        </Row>


                    </Card>
                </Row>


            ) : show == '1' ? (
                <Row style={{ justifyContent: 'center' }}>
                    <Card className='card'>
                        <div class="title-primary" style={{ textAlign: 'center' }} ><b>Cargando ...</b></div>


                    </Card>
                </Row>


            ) : show == '4' ? (
                <Row style={{ justifyContent: 'center' }}>
                    <Card className='card'>
                        <div class="title-primary" style={{ textAlign: 'center' }} ><b>Url no válido</b></div>


                    </Card>
                </Row>


            ) : (
                            <Row style={{ justifyContent: 'center' }}>
                                <Card className='card'>
                                    <div class="title-primary" style={{ textAlign: 'center' }} ><b>{activate_message} {auth_message} </b></div>



                                    <br></br>
                                    <Row style={{ justifyContent: 'center' }}>


                                        <Button size="small" onClick={() => { window.location = '/login' }} className='btn-success'>Ingresar</Button>



                                    </Row>
                                </Card>

                            </Row>


                        )


            }

            {
                showAuth == '1' ? (
                    <Row style={{ justifyContent: 'center' }}>
                        <Card className='card'>
                            <div class="title-primary" style={{ textAlign: 'center' }} ><b>Url no válido</b></div>


                        </Card>
                    </Row>


                ) : ''
            }


        </div >
    );

}

const PasswordRequirements = ({ password_requirements, minLength, maxLength, capitalLetters, lowLetters, number, specialChar }) => {
    return (<>
        <p>Su contraseña debe tener:</p>
        <span style={{ fontSize: '0.8rem', display: password_requirements.MINIMUN_PASSWORD_LENGTH.status == 1 ? 'flex' : 'none' }} >
            <CheckCircleOutlined style={{ marginRight: '0.8rem', fontSize: '1.2rem', color: minLength ? 'green' : 'black' }} />
        Al menos {password_requirements.MINIMUN_PASSWORD_LENGTH.value} caracteres.
        </span>
        <span style={{ fontSize: '0.8rem', display: password_requirements.MAXIMUM_PASSWORD_LENGTH.status == 1 ? 'flex' : 'none' }} >
            <CheckCircleOutlined style={{ marginRight: '0.8rem', fontSize: '1.2rem', color: maxLength ? 'green' : 'black' }} />
        Como máximo {password_requirements.MAXIMUM_PASSWORD_LENGTH.value} caracteres.
        </span>
        <span style={{ fontSize: '0.8rem', display: password_requirements.REQUIRE_LOWER_LETTERS.value == 1 ? 'flex' : 'none' }} >
            <CheckCircleOutlined style={{ marginRight: '0.8rem', fontSize: '1.2rem', color: lowLetters ? 'green' : 'black' }} />
        Al menos una minúscula
        </span>
        <span style={{ fontSize: '0.8rem', display: password_requirements.REQUIRE_CAPITAL_LETTERS.value == 1 ? 'flex' : 'none' }} >
            <CheckCircleOutlined style={{ marginRight: '0.8rem', fontSize: '1.2rem', color: capitalLetters ? 'green' : 'black' }} />
        Al menos una mayúscula
        </span>
        <span style={{ fontSize: '0.8rem', display: password_requirements.REQUIRE_NUMBER.value == 1 ? 'flex' : 'none' }} >
            <CheckCircleOutlined style={{ marginRight: '0.8rem', fontSize: '1.2rem', color: number ? 'green' : 'black' }} />
        Al menos un número
        </span>
        <span style={{ fontSize: '0.8rem', display: password_requirements.REQUIRE_CARACTER_ESPECIAL.value == 1 ? 'flex' : 'none' }} >
            <CheckCircleOutlined style={{ marginRight: '0.8rem', fontSize: '1.2rem', color: specialChar ? 'green' : 'black' }} />
        Al menos un caracter especial !@#$%+*_-.
        </span>
    </>);
}


export default connect(null, { activePassword })(Activate);

