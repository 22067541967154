import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import "../styles/welcome.scss"
import logo from "../imgs/logo_pacifico_small.png"

import calendar from "../imgs/calendar.png"
import evaluations from "../imgs/evaluations.png"
import recommendations from "../imgs/recommendations.png"
import { Row, Col, Divider } from 'antd';


import { MenuOutlined } from '@ant-design/icons';

const Welcome = (props) => {
    return(
        <div className="welcome-content">
            <div className="navbar">
                <img src={logo}/>
                <div className="nav-container">
                    <a href="#home">Inicio</a>
                    <a href="#what-can-i-do">¿Qué puedo realizar?</a>
                    <a href="#get-in">¿Cómo ingresar?</a>
                </div>
            </div>
            <section id="home" className="section-welcome">
                <div className="bg-welcome"></div>
                <div className="welcome-title">
                    <h1>Bienvenido al portal de Gestión de Riesgos de Pacífico</h1>
                </div>
                <div className="welcome-buttons">
                    <Link className="btn-orange" to="/register">Registrarse</Link>
                    <Link className="btn-orange" to="/login">Iniciar Sesión</Link>
                </div>
            </section>
            <section id="what-can-i-do" className="section-what-can-i-do background-gradient-vertical">
                <h2>¿Qué puedo realizar en este portal?</h2>
                <Row className="actions-subsection">
                    <Col className="action-item" xs={{ span: 24  }} sm={{ span: 12}} md={{ span: 8 }} lg={{ span: 8 }}>
                        <img src={evaluations} />
                        <h3>Realizar evaluaciones</h3>
                        <p>Tus colaboradores podrán ingresar al portal a realizar sus evaluaciones.</p>
                    </Col>
                    <Col className="action-item" xs={{ span: 24 }} sm={{ span: 12}} md={{ span: 8 }} lg={{ span: 8 }}>
                    <img src={calendar} />
                        <h3>Programar asesorías</h3>
                        <p>Tus colaboradores podrán programar asesorías de acuerdo a sus evaluaciones y disponibilidad de asesores.</p>
                    </Col>
                    <Col className="action-item" xs={{ span: 24 }} sm={{ span: 24}} md={{ span: 8 }} lg={{ span: 8 }}>
                    <img src={recommendations} />
                        <h3>Realizar recommendaciones</h3>
                        <p>Los asesores podrán realizar recomendaciones de acuerdo a los resultados de evaluaciones.</p>
                    </Col>
                </Row>
            </section>
            <section id="get-in" className="section-get-in">
                <Row>
                    <Col className="action-item" xs={{ span: 24 }} sm={{ span: 10 }} md={{ span: 10 }} lg={{ span: 10 }}>
                        <div className="img-get-in"></div>
                    </Col>
                    <Col className="action-item"  xs={{ span: 24 }} sm={{ span: 14 }} md={{ span: 14 }} lg={{ span: 14 }}>
                        <h3>¿Cómo ingreso al portal?</h3>
                        <div className="info-get-in">
                            <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Check_green_icon.svg/1200px-Check_green_icon.svg.png" />
                            <p>Genera el acceso de tu empresa registrando tus datos.</p>
                        </div>
                    </Col>
                        
                </Row>
            </section>
            <footer>
                <p>Av. Juan de Arona 830, San Isidro - Lima 27 - Web Pacífico</p>
            </footer>
            
        </div>
    );

}

export default Welcome;