import {LIST_CLIENT_USERS,CLIENT_ACCESS_STATUS_ACTIONS,ADD_CLIENT_ACCESS,GET_ACCESS_CLIENT,CLIENT_ACCESS_VISIBLE,CLIENT_ACCESS_LOADING, UPDATE_CLIENT_ACCESS, ACTIVE_CLIENT_ACCESS,
    DELETE_CLIENT_ACCESS, RESET_PASSWORD__CLIENT_ACCESS} from "../ActionTypes";

const INIT_STATE = {
    clientUsersList: [],
    clientAccessObject: [],
    accessvisible:false,
    loading:false,
    clientAccessStatusActions: 0,
    newCredentials: []
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case ADD_CLIENT_ACCESS: {
        return {
            ...state,
            newCredentials: action.payload
        }
    }

    case UPDATE_CLIENT_ACCESS:{
        return {
            ...state
        }
    }

    case LIST_CLIENT_USERS: {
        state.clientUsersList.some(e=> e.id == action.payload.id) && state.clientUsersList.splice(state.clientUsersList.findIndex(c=>c.id==action.payload.id),1)
        return {
          ...state,
          clientUsersList: state.clientUsersList.concat({"id":action.payload.id,"data":action.payload.data}),
        };
      }

    case CLIENT_ACCESS_STATUS_ACTIONS: {
    return {
        ...state,
        clientAccessStatusActions:action.payload
    }
    }

    case GET_ACCESS_CLIENT: {
        return {
          ...state,
          clientAccessObject: action.payload,
        };
      }   
      
    case CLIENT_ACCESS_VISIBLE: {
      return {
      ...state,
      accessvisible: action.payload,
      }
    }

    case CLIENT_ACCESS_LOADING: {
      return {
      ...state,
      loading: action.payload,
      }
   }

   case ACTIVE_CLIENT_ACCESS: {
        return {
            ...state
        }
    }   

    case DELETE_CLIENT_ACCESS: {
        return {
            ...state
        }
    }

    case RESET_PASSWORD__CLIENT_ACCESS: {
        return {
            ...state
        }
    }

    default:
      return state;
  }
}
