import React, { useState, useEffect } from "react";
import { Switch, TimePicker, Select, DatePicker, Card, Button, Table, Tag, Popconfirm, message, Modal, Input, Space, Row, Col } from "antd";
import { Link } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";

import { CheckCircleTwoTone, PlusOutlined, ArrowLeftOutlined, FilePdfOutlined, FileZipOutlined, FileTextOutlined, DeleteOutlined, ShareAltOutlined, QuestionCircleOutlined, CloudDownloadOutlined, ReloadOutlined } from "@ant-design/icons";


import axios from '../util/Api'
import "../styles/expert.scss"
import 'moment/locale/es-mx';
import moment from 'moment';
import { SearchOutlined, CalendarOutlined } from '@ant-design/icons';
import "../styles/advisory.scss"

const { Option } = Select;
const { RangePicker } = DatePicker;
const { confirm } = Modal;

const CourseList = (props) => {
  const [loadingZipCertificate, setLoadingZipCertificate] = useState(-1)
  const [loadingZipConstancy, setLoadingZipConstancy] = useState(-1)

  const [course, setCourse] = useState(0)
  const [showModalDownloadCertificate, setShowModalDownloadCertificate] = useState(false)
  const [showModalDownloadConstancy, setShowModalDownloadConstancy] = useState(false)
  const [showModalLink, setShowModalLink] = useState(false)

  const [token, setToken] = useState('')
  const [data, setData] = useState([])
  const [error, setError] = useState('')

  const [loadingLetter, setLoadingLetter] = useState(false)


  const [listCourses, setListCourses] = useState([])
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [loadingCertificate, setLoadingCertificate] = useState(0)
  const [loadingConstancy, setLoadingConstancy] = useState(0)
  const [searchInput, setSearchInput] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [searchName, setSearchName] = useState('')




  useEffect(() => {
    let params = new URLSearchParams(props.location.search);
    setToken(params.get('token'))
  }, [])

  useEffect(() => {
    if (token != '') {
      getData()
    }

  }, [token])

  useEffect(() => {

    if (searchName.length > 2) {
      fnListParticipants()

    } else {
      setListCourses([])
    }
  }, [searchName])


  //LISTA DE PARTICIPANTES

  const fnListParticipants = () => {
    axios.post(`/external/courseparticipants/list?&page=${page}&filters=${JSON.stringify(filters)}`, { token: token, search: searchName }).then(({ data }) => {
      if (data) {

        setListCourses(data)

      } else {
        message.error(data.message);

      }
    }).catch(function (error) {
      console.log(error)
      message.error(error.response.data.message);


    });


  }


  const handleChangePage = (newpage) => {
    setPage(newpage)
    fnListParticipants()

  }
  const paginationConfig = {
    total: listCourses.meta ? listCourses.meta.total : 0,
    onChange: handleChangePage,
    defaultPageSize: listCourses.meta ? listCourses.meta.per_page : 50,
    current: listCourses.meta ? listCourses.meta.current_page : 0,
    showSizeChanger: false
  }




  const getColumnSearchProps = (nameColumn, dataIndex) => {

    if (data.type === 'WEBINAR') {
      return {}
    } else {
      return {
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => { setSearchInput(node); }}
              placeholder={`Buscar ${nameColumn}`}
              value={selectedKeys}
              onKeyUp={(e) => saveFilters(e.target.value ? e.target.value : '', dataIndex)}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button type="primary" onClick={() => handleSearch(selectedKeys, confirm, dataIndex)} icon={<SearchOutlined />} size="small" style={{ width: 90 }}> Buscar</Button>
              <Button onClick={() => handleReset(clearFilters, dataIndex)} size="small" style={{ width: 90 }}>Borrar</Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            if (searchInput != null) setTimeout(() => searchInput.select(), 100);
          }
        },

      }
    }

  }
    ;

  const saveFilters = (event, indexName) => {
    if (event) {
      let arrayFilters = filters;
      let exist = arrayFilters.find(element => { return element.column == indexName });
      if (exist) {
        if (arrayFilters.length > 0) {
          arrayFilters.forEach(element => {
            if (element.column == indexName) {
              element.value = event;
            }
          })
        }
      } else {
        arrayFilters.push({
          column: indexName,
          value: event
        })
      }
      setFilters(arrayFilters)
    }
    ;
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters, indexName) => {
    setSearchText('');
    let arrayFilters = filters;
    let row = arrayFilters.find(element => { return element.column == indexName });
    let index = arrayFilters.indexOf(row);
    arrayFilters.splice(index, 1);
    setFilters(arrayFilters)
    clearFilters();
  };




  let columns = [
    {
      title: '',
      key: 'index',
      width: 50,
      render: (text, record, index) => index + (listCourses.meta ? listCourses.meta.from : 0),
    },

    {
      title: 'Nombres y Apellidos',
      dataIndex: 'fullName',
      width: 270,
      ...getColumnSearchProps('Nombres', 'fullName')
    },
    {
      title: '  Documento',
      dataIndex: 'document',
      width: 100,
      ...getColumnSearchProps(' Documento', 'document')
    },



    // {
    //   title: 'Estado',
    //   dataIndex: 'active',
    //   width: 150,
    //   render: (text) => (
    //     <span>
    //       {text == 1 ? <Tag color="#87d068">Activo</Tag> : <Tag color="#f50">Inactivo</Tag>}
    //     </span>
    //   )
    // }
  ];

  if (data.type === 'CAPACITACIÓN') {
    columns = [...columns, ...[{
      title: '  Calificación',
      dataIndex: 'calification',
      width: 100,
      align: 'right',
      render: (text, record) => (
        <span>
          {record.approved == 1 ? <Tag color="#87d068">{text}</Tag> : text != '' ? <Tag color="#f50">{text}</Tag> : 'SN'}
        </span>
      ),
      ...getColumnSearchProps(' Calificación', 'calification'),

    }]]
  }


  columns.push(
    {
      title: 'Certificado/Constancia',
      dataIndex: 'certificate',
      width: 210,
      align: 'right',

      render: (text, record) => (

        <span>
          <Button loading={loadingCertificate == record.idParticipant ? true : false} type="default" size="small" title="Descargar Certificado/Constancia" onClick={e => handleOnGeneratePDF(e, record.idParticipant)} icon={<CloudDownloadOutlined />} >
            Descargar
          </Button>
        </span>

        // record.approved == 1 ?

        // record.certificate == 1 ?
        //   <span>
        //     <Button loading={loadingCertificate == record.idParticipant ? true : false} type="default" size="small" title="Actualizar" onClick={e => handleOnGenerateCertificate(e, record.idParticipant)} icon={<ReloadOutlined />} >

        //     </Button>
        //     {/* <Button loading={loadingCertificate == record.idParticipant ? true : false} type="default" size="small" title="Generar" onClick={e => handleOnDownloadCertificate(e, record.idParticipant)} icon={<CloudDownloadOutlined />} >
        //       Generar Certificado
        // </Button> */}

        //   </span>

        //   :
        // <span>
        //   <Button loading={loadingCertificate == record.idParticipant ? true : false} type="default" size="small" title="Generar" onClick={e => handleOnGenerateCertificate(e, record.idParticipant)} icon={<CloudDownloadOutlined />} >
        //     Certificado
        //   </Button>
        // </span>

        // :

        // record.constancy == 1 ?
        //   <span>
        //     <Button loading={loadingConstancy == record.idParticipant ? true : false} type="default" size="small" title="Actualizar" onClick={e => handleOnGenerateConstancy(e, record.idParticipant)} icon={<ReloadOutlined />} >

        //     </Button>
        //     {/* <Button loading={loadingConstancy == record.idParticipant ? true : false} type="default" size="small" title="Generar" onClick={e => handleOnDownloadConstancy(e, record.idParticipant)} icon={<CloudDownloadOutlined />} >
        //       Generar Constancia
        // </Button> */}

        //   </span>

        //   :
        // <span>
        //   <Button loading={loadingConstancy == record.idParticipant ? true : false} type="default" size="small" title="Generar" onClick={e => handleOnGenerateConstancy(e, record.idParticipant)} icon={<CloudDownloadOutlined />} >
        //     Constancia
        //   </Button>

        // </span>

      )
    }
  )

  function handleOnGeneratePDF(e, id) {
    // let config = {
    //   headers: {
    //     "X-Requested-With": "XMLHttpRequest",
    //   },
    // };
    setLoadingCertificate(id)
    axios
      .post("/external/certificate/generate-pdf", { idParticipant: id })
      .then(({ data, status }) => {


        if (data) {
          message.success(data.message);
          // window.open(data.url)
          let a = document.createElement('a');
          a.href = "data:application/pdf;base64," + data.pdfB64;
          a.download = 'certificado-' + data.document + '.pdf';

          document.body.appendChild(a);
          a.click()

          setLoadingCertificate(0)


        } else {

          message.error(data.message);
          setLoadingCertificate(0)

        }
      })
      .catch(function (error) {
        setLoadingCertificate(0)

        message.error(error.response.data.message);


      });
  }

  function handleOnGenerateCertificate(e, id) {
    // let config = {
    //   headers: {
    //     "X-Requested-With": "XMLHttpRequest",
    //   },
    // };
    setLoadingCertificate(id)
    axios
      .post("/external/certificate/generatecertificate/" + id)
      .then(({ data, status }) => {


        if (data) {
          message.success(data.message);
          // window.open(data.url)
          let a = document.createElement('a');
          a.href = "data:application/pdf;base64," + data.pdfB64;
          a.download = 'certificado-' + data.document + '.pdf';

          document.body.appendChild(a);
          a.click()

          setLoadingCertificate(0)


        } else {

          message.error(data.message);
          setLoadingCertificate(0)

        }
      })
      .catch(function (error) {
        setLoadingCertificate(0)

        message.error(error.response.data.message);


      });
  }


  function handleOnGenerateConstancy(e, id) {
    // let config = {
    //   headers: {
    //     "X-Requested-With": "XMLHttpRequest",
    //   },
    // };

    setLoadingConstancy(id)
    axios
      .post("/external/certificate/generateconstancy/" + id)
      .then(async ({ data, status }) => {


        if (data) {
          message.success(data.message);

          // window.open(data.url)
          let a = document.createElement('a');
          a.href = "data:application/pdf;base64," + data.pdfB64;
          a.download = 'constancia-' + data.document + '.pdf';

          document.body.appendChild(a);
          await a.click()
          document.body.removeChild(a);

          setLoadingConstancy(0)


        } else {

          message.error(data.message);
          setLoadingConstancy(0)


        }
      })
      .catch(function (error) {

        message.error(error.response.data.message);
        setLoadingConstancy(0)


      });
  }
  //////////////////////////////////////////


  function getData() {
    axios.post(`/external/coursesharelink`, { token: token }).then(({ data }) => {
      if (data) {
        setData(data)
        if (data.type === 'CAPACITACIÓN') {
          fnListParticipants()

        }

      } else {
        setError(data.message)
      }
    }).catch(function (error) {
      console.log(error)
      setError('Ocurrió un error')


    });
  }


  function handleOnGenerateZipCertificate(e, start, name) {
    // let config = {
    //   headers: {
    //     "X-Requested-With": "XMLHttpRequest",
    //     'Access-Control-Allow-Origin': '*'
    //   },
    // };

    setLoadingZipCertificate(start)
    axios
      .post("/external/downloadfile", { name: name, type: 'certificate', token })
      .then(async ({ data, status }) => {


        if (data) {
          message.success(data.message);

          // window.open(data.url,'_blank')
          let a = document.createElement('a');
          // a.href = data.url;
          a.href = "data:application/pdf;base64," + data.pdfB64;
          a.download = `certificados:${name}.pdf`;
          document.body.appendChild(a);
          a.click()
          document.body.removeChild(a);

          setLoadingZipCertificate(-1)

          // fnListParticipants()

        } else {
          setLoadingZipCertificate(-1)

          message.error(data.message);


        }
      })
      .catch(function (error) {
        setLoadingZipCertificate(-1)
        if (error.response) {
          message.error(error.response.data.message);

        } else {
          message.error('Error al descargar el archivo zip. Intente nuevamente');
          console.log(error)

        }


      });
  }

  function handleOnGenerateZipConstancy(e, start, name) {
    // let config = {
    //   headers: {
    //     "X-Requested-With": "XMLHttpRequest",
    //   },
    // };

    setLoadingZipConstancy(start)
    axios
      .post("/external/downloadfile", { name: name, type: 'constancy', token })
      .then(async ({ data, status }) => {


        if (data) {
          message.success(data.message);

          let a = document.createElement('a');
          // a.href = data.url;
          a.href = "data:application/pdf;base64," + data.pdfB64;
          a.download = `constancias:${name}.pdf`;
          document.body.appendChild(a);
          a.click()
          document.body.removeChild(a);

          setLoadingZipConstancy(-1)

          // fnListParticipants()

        } else {

          setLoadingZipConstancy(-1)
          message.error(data.message);


        }
      })
      .catch(function (error) {

        setLoadingZipConstancy(-1)
        if (error.response) {
          message.error(error.response.data.message);

        } else {
          message.error('Error al descargar el archivo . Intente nuevamente');
          console.log(error)
        }


      });
  }


  function handleOnGenerateLetter(e, start, name) {
    // let config = {
    //   headers: {
    //     "X-Requested-With": "XMLHttpRequest",
    //   },
    // };

    setLoadingLetter(true)
    axios
      .post("/external/generateLetterExternal", { token })
      .then(async ({ data, status }) => {


        if (data) {
          message.success(data.message);

          let a = document.createElement('a');
          // a.href = data.url;
          a.href = "data:application/pdf;base64," + data.pdfB64;
          a.download = `carta_${data.courseName}.pdf`;
          document.body.appendChild(a);
          a.click()
          document.body.removeChild(a);

          setLoadingLetter(false)


        } else {

          setLoadingLetter(false)
          message.error(data.message);


        }
      })
      .catch(function (error) {

        setLoadingZipConstancy(-1)
        if (error.response) {
          message.error(error.response.data.message);

        } else {
          message.error('Error al descargar el archivo . Intente nuevamente');
          console.log(error)
        }


      });
  }








  const getHtmlDownloadCertificate = () => {



    let content = [];
    for (let i = 0; i < data.approved; i = i + 100) {

      let dh = (i + 1) + '-' + (i + 100 > data.approved ? data.approved : i + 100)
      let count = data.files ? (data.files).filter(f => f.name == dh && f.type == 'certificate').length : 0;

      {
        count > 0 ?
          content.push(
            <div>

              <Button style={{ backgroundColor: 'white', color: '#319FE2' }}
                disabled={loadingZipCertificate != -1 || loadingZipConstancy != -1 ? true : false}
                loading={loadingZipCertificate == i ? true : false}
                icon={<CloudDownloadOutlined />}
                onClick={(e) => handleOnGenerateZipCertificate(e, i, dh)} >Descargar Certificados PDF ({dh}) </Button>
            </div>
          )
          : content.push(<span>Los certificados aún no se han generado.</span>)
      }


    }

    return content
  }

  const getHtmlDownloadConstancy = () => {

    let content = [];
    for (let i = 0; i < data.desapproved; i = i + 100) {

      let dh = (i + 1) + '-' + (i + 100 > data.desapproved ? data.desapproved : i + 100)
      let count = data.files ? (data.files).filter(f => f.name == dh && f.type == 'constancy').length : 0;
      {
        count > 0 ?
          content.push(
            <div>

              <Button style={{ backgroundColor: 'white', color: '#319FE2' }}
                disabled={loadingZipCertificate != -1 || loadingZipConstancy != -1 ? true : false}
                loading={loadingZipConstancy == i ? true : false}
                icon={<CloudDownloadOutlined />}
                onClick={(e) => handleOnGenerateZipConstancy(e, i, dh)} >Descargar Constancias PDF ({dh}) </Button>
            </div>
          )
          : content.push(<span>Las constancias aún no se han generado.</span>)

      }

    }

    return content
  }

  return (

    <div class='body '>

      <br></br>
      <br></br>

      {error != '' ?
        <Col className='col-validate' xs={24} lg={12}>
          <div className='card-validate' >
            <h1 className="title_page" style={{ textAlign: 'center' }} >{error}</h1>

            <br></br>

          </div>
        </Col>

        :
        <>

          <Col className='col-validate' xs={24} lg={12}>
            <div className='card-validate' style={{ textAlign: 'center' }}>
              <b style={{ fontSize: '20px !important', fontWeight: 'bold' }} >DESCARGUE SUS CERTIFICADOS Y/O CONSTANCIAS</b>
              <br></br>
              <br></br>

              <Row style={{ textAlign: 'left', margin: 'auto 0px', width: '100%' }}>


                <ul>

                  <Col xs={24} lg={24} >
                    <li> <b style={{ color: '#319FE2', fontSize: '16px !important' }}>Curso:</b> {data.courseName}</li>
                  </Col>
                  <Col xs={24} lg={24} >
                    <li> <b style={{ color: '#319FE2', fontSize: '16px !important' }}>Tipo:</b> {data.type}</li>
                  </Col>
                  <Col xs={24} lg={24} >
                    <li> <b style={{ color: '#319FE2', fontSize: '16px !important' }}>Modalidad:</b> {data.modality}</li>
                  </Col>
                  <Col xs={24} lg={24} >
                    <li> <b style={{ color: '#319FE2', fontSize: '16px !important' }}>Carga horaria:</b> {data.hours} h.</li>
                  </Col>
                  <Col xs={24} lg={24} >
                    <li> <b style={{ color: '#319FE2', fontSize: '16px !important' }}>Expositor:</b> {data.expositor}</li>
                  </Col>
                  {
                    data.showConsulting === 'SI' ?
                      <Col xs={24} lg={24} >
                        <li> <b style={{ color: '#319FE2', fontSize: '16px !important' }}>Consultora:</b> {data.consulting}</li>
                      </Col>
                      : <></>
                  }

                </ul>



              </Row>

              {data.type === 'CAPACITACIÓN' ?


                <div style={{ textAlign: 'center' }}>
                  {data.approved > 0 ? <Button style={{ backgroundColor: '#319FE2', color: 'white' }} icon={<FilePdfOutlined />} onClick={(e) => { setShowModalDownloadCertificate(true) }} >Descargar Certificados  ({data.approved}) </Button> : ''}
                  {data.desapproved > 0 ? <Button style={{ backgroundColor: '#319FE2', color: 'white' }} icon={<FilePdfOutlined />} onClick={(e) => { setShowModalDownloadConstancy(true) }} >Descargar Constancias  ({data.desapproved}) </Button> : ''}
                  {data.haveClient === 1 ? <Button loading={loadingLetter} style={{ backgroundColor: 'white', color: '#319FE2' }} icon={<FileTextOutlined />} onClick={(e) => { handleOnGenerateLetter() }} >Generar Carta </Button> : ''}

                </div>
                : <>
                  <br></br>
                  <div style={{ width: '95%', textAlign: 'left' }}>

                    <label style={{ paddingLeft: '8px', fontWeight: 'bold' }}>Buscar por nombre o DNI : </label>
                    <Input

                      className='search-input'
                      placeholder="Ingrese más de 2 caracteres"
                      onChange={(e) => setSearchName(e.target.value)}
                    />

                  </div>
                </>
              }



              <br></br>

              <Table className="gx-table-responsive"
                rowKey="idParticipant"
                // rowSelection={rowSelection}
                pagination={paginationConfig}
                columns={columns} dataSource={listCourses.data}
                size="small"
                scroll={{ x: 600, y: 190 }} />

              <p>Nota: Este enlace es válido hasta el {data.expirationDate}</p>


            </div>


          </Col>



          <Modal title='Descargar Certificados' visible={showModalDownloadCertificate} onOk={() => { setShowModalDownloadCertificate(false) }} onCancel={() => { setShowModalDownloadCertificate(false) }} okButtonProps={{ style: { display: 'none' } }} cancelButtonProps={{ style: { display: 'none' } }}>

            {
              data.approved > 0 ?
                <div>
                  {

                    getHtmlDownloadCertificate()

                  }

                </div>

                : <p>No hay certificados para descargar</p>
            }

          </Modal>
          <Modal title='Descargar Constancias' visible={showModalDownloadConstancy} onOk={() => { setShowModalDownloadConstancy(false) }} onCancel={() => { setShowModalDownloadConstancy(false) }} okButtonProps={{ style: { display: 'none' } }} cancelButtonProps={{ style: { display: 'none' } }}>

            {
              data.desapproved > 0 ?
                <div>
                  {

                    getHtmlDownloadConstancy()

                  }

                </div>

                : <p>No hay constancias para descargar</p>
            }

          </Modal>



        </>
      }


    </div>
  );
};



export default CourseList;
