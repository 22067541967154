import {
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_ERROR,
  SHOW_MESSAGE,
 ACTIVATE_LOADING,
 ACTIVATE_VISIBLE,
 ACTIVATE_STATUS_CREATE,
 ACTIVATE_STATUS_ACTIONS,
  ADD_ACTIVATE,
  ACTIVATE_MESSAGE
  } from "../ActionTypes";
  import axios from '../../util/Api'
  
   

  export const activate = (code) => {
    
    let config = {
        headers: {
          'X-Requested-With': 'XMLHttpRequest'
        }
    }
    
    return (dispatch) => {
        dispatch({type:ACTIVATE_LOADING,payload:true});
        dispatch({type: FETCH_START});
        axios.post('/external/clients/active',
        {code:code},
            config
        ).then(({data,status}) => {
          console.log(data)
          console.log(data)
            dispatch({type:ACTIVATE_LOADING,payload:false});

            if (data) {
                dispatch({type: FETCH_SUCCESS});
                localStorage.setItem("token", JSON.stringify(data.access_token));
               axios.defaults.headers.common['Authorization'] = "Bearer " + data.access_token;

                dispatch({type: ADD_ACTIVATE});
                dispatch({type: ACTIVATE_MESSAGE,payload: data.message});
                dispatch({type: SHOW_MESSAGE, payload: data.message});
                dispatch({type:ACTIVATE_VISIBLE,payload:false});
                 dispatch({type:ACTIVATE_STATUS_ACTIONS, payload: status});

                
            } else {
                dispatch({type: FETCH_ERROR, payload: data.message});
            }

        })
        .catch(function (error) {
          console.log(error.response)
            dispatch({type:ACTIVATE_LOADING,payload:false});
            dispatch({type: ACTIVATE_MESSAGE,payload: error.response.data.message});

            dispatch({type: FETCH_ERROR, payload: error.response.data.message});
        });
    }
};
