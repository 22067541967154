import {LIST_SUPERCLIENTS,SUPERCLIENT_STATUS_CREATE,UPDATE_SUPERCLIENT,GET_SUPERCLIENT,ACTIVE_SUPERCLIENT,
   DELETE_SUPERCLIENT,SUPERCLIENT_STATUS_ACTIONS,SUPERCLIENT_LOADING, ADD_SUPERCLIENT, SUPERCLIENT_VISIBLE,LIST_SUPERCLIENTS_SELECT,LIST_SUPERCLIENT_COMPANIES,ACTIVE_SUPERCLIENT_COMPANIES,DELETE_SUPERCLIENT_COMPANIES, VISIBLE_COMPANY} from "../ActionTypes";

const INIT_STATE = {
  superclientsList: [],
  superclientListSelect:[],
  superclientObject: [],
  superClientCompaniesList: [],
  superclientStatusActions: 0,
  superClientCompaniesStatus: false,
  superClientCompaniesStatusActions: 0,
  loading:false,
  visible:false,
  visibleCompany: false,
  superclientStatusCreate: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case LIST_SUPERCLIENTS: {
      return {
        ...state,
        superclientsList: action.payload,
      };
    }

    case LIST_SUPERCLIENTS_SELECT: {
      return {
        ...state,
        superclientListSelect: action.payload,
      };
    }

    case LIST_SUPERCLIENT_COMPANIES: {
      return {
        ...state,
        superClientCompaniesList: action.payload,
      };
    }

    case ACTIVE_SUPERCLIENT_COMPANIES: {
      return {
          ...state,
          superClientCompaniesStatus :action.payload
      }
    }

    case DELETE_SUPERCLIENT_COMPANIES: {
      return {
          ...state
      }
    }   

    case SUPERCLIENT_STATUS_CREATE: {
      return {
          ...state,
          superclientStatusCreate:action.payload
      }
    }

    case GET_SUPERCLIENT: {
      return {
        ...state,
        superclientObject: action.payload,
      };
    }

    case ACTIVE_SUPERCLIENT: {
        return {
            ...state
        }
    }   

    case DELETE_SUPERCLIENT: {
        return {
            ...state
        }
    }

    case SUPERCLIENT_STATUS_ACTIONS: {
      return {
          ...state,
          superclientStatusActions:action.payload
      }
    }

    case SUPERCLIENT_LOADING: {
      return {
      ...state,
      loading: action.payload,
      }
   }

   case ADD_SUPERCLIENT: {
      return {
          ...state
      }
    }

    case SUPERCLIENT_VISIBLE: {
      return {
      ...state,
      visible: action.payload,
      }
    }

    case VISIBLE_COMPANY: {
      return {
        ...state,
        visibleCompany: action.payload,
        }
    }

    case UPDATE_SUPERCLIENT: {
      return {
          ...state
      }
  }

    default:
      return state;
  }
}
