import { 

  LIST_RECOMENDATION,
  LIST_EVALUATION,
  LIST_CRITERIA,

  GET_RECOMENDATION,
  GET_COMMENT,
  COMMENT_VISIBLE,
  DELETE_RECOMENDATION,
  RECOMENDATION_STATUS_ACTIONS,
  RECOMENDATION_LOADING,
  ADD_RECOMENDATION,
  ADD_COMMENT,
  RECOMENDATION_VISIBLE,
  RECOMENDATION_STATUS_CREATE,
  UPDATE_RECOMENDATION
 } from "../ActionTypes";

const INIT_STATE = {
  recomendationList: [],
  evaluationList: [],
  criteriaList: [],

  recomendationObject: [],
  commentObject: [],
  recomendationStatusActions: 0,
  loading:false,
  visible:false,
  commentVisible: false,
  recomendationStatusCreate: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case LIST_RECOMENDATION: {
      return {
        ...state,
        recomendationList: action.payload,
      };
    }

    case LIST_EVALUATION: {
      return {
        ...state,
        evaluationList: action.payload,
      };
    }

    case LIST_CRITERIA: {
      return {
        ...state,
        criteriaList: action.payload,
      };
    }
/*
    case CLIENT_STATUS_CREATE: {
      return {
          ...state,
          clientStatusCreate:action.payload
      }
    }
*/
    case GET_RECOMENDATION: {
      return {
        ...state,
        recomendationObject: action.payload,
      };
    }

    case GET_COMMENT: {
      return {
        ...state,
        commentObject: action.payload,
      };
    }

    case COMMENT_VISIBLE: {
      return {
      ...state,
      commentVisible: action.payload,
      }
    }

    // case ACTIVE_RECOMENDATION: {
    //     return {
    //         ...state
    //     }
    // }   

    case DELETE_RECOMENDATION: {
        return {
            ...state
        }
    }

    case RECOMENDATION_STATUS_ACTIONS: {
      return {
          ...state,
          recomendationStatusActions:action.payload
      }
    }

    case RECOMENDATION_LOADING: {
      return {
      ...state,
      loading: action.payload,
      }
   }

   case ADD_RECOMENDATION: {
      return {
          ...state
      }
    }

    case ADD_COMMENT: {
      return {
          ...state
      }
    }
    

    case RECOMENDATION_VISIBLE: {
      return {
      ...state,
      visible: action.payload,
      }
    }

/*     case UPDATE_CLIENT: {
      return {
          ...state
      }
  } */

    default:
      return state;
  }
}
