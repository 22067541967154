import {LIST_CONTACTS,CONTACT_STATUS_CREATE,UPDATE_CONTACT,GET_CONTACT,ACTIVE_CONTACT,
   DELETE_CONTACT,CONTACT_STATUS_ACTIONS,CONTACT_LOADING, ADD_CONTACT, CONTACT_VISIBLE,LIST_CONTACTS_SELECT} from "../ActionTypes";

const INIT_STATE = {
  contactList: [],
  contactListSelect:[],
  contactObject: [],
  contactStatusActions: 0,
  loading:false,
  visibleContact:false,
  contactStatusCreate: 0,
  newCredentials: []

};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case LIST_CONTACTS: {
      return {
        ...state,
        contactList: action.payload,
      };
    }

    case LIST_CONTACTS_SELECT: {
      return {
        ...state,
        contactListSelect: action.payload,
      };
    }

    case CONTACT_STATUS_CREATE: {
      return {
          ...state,
          contactStatusCreate:action.payload
      }
    }

    case GET_CONTACT: {
      return {
        ...state,
        contactObject: action.payload,
      };
    }

    case ACTIVE_CONTACT: {
        return {
            ...state
        }
    }   

    case DELETE_CONTACT: {
        return {
            ...state
        }
    }

    case CONTACT_STATUS_ACTIONS: {
      return {
          ...state,
          contactStatusActions:action.payload
      }
    }

    case CONTACT_LOADING: {
      return {
      ...state,
      loading: action.payload,
      }
   }

   case ADD_CONTACT: {
      return {
          ...state,
          newCredentials: action.payload

      }
    }

    case CONTACT_VISIBLE: {
      return {
      ...state,
      visibleContact: action.payload,
      }
    }

    case UPDATE_CONTACT: {
      return {
          ...state
      }
  }

    default:
      return state;
  }
}
