import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import Common from "./Common";
import Auth from "./Auth";
import Client from "./Client";
import ClientUsers from "./ClientUsers";
import Register from "./Register";
import Activate from "./Activate";
import PreventionEngineer from "./PreventionEngineer";
import Expert from "./Expert";
import CheckList from "./Checklist";
import Autodiagnostic from "./Autodiagnostic";
import Report from "./Report";
import Advisory from "./Advisory";
import Recomendation from "./Recomendation";
import Availability from "./Availability";
import SuperClient from "./SuperClient";
import BranchOffice from "./BranchOffice";
import Contact from "./Contact";
import Area from "./Area";
import Quiz from "./Quiz";
import ProgramingQuiz from "./ProgramingQuiz";







//import Settings from "./Settings";

export default (history) =>
    combineReducers({
        router: connectRouter(history),
        commonData: Common,
        auth: Auth,
        client: Client,
        clientUser: ClientUsers,
        register: Register,
        activate: Activate,
        preventionEngineer: PreventionEngineer,
        expert: Expert,
        checklist: CheckList,
        autodiagnostic: Autodiagnostic,
        report: Report,
        advisory: Advisory,
        recomendation: Recomendation,
        availability:Availability,
        superClient: SuperClient,
        branchOffice: BranchOffice,
        contact: Contact,
        area:Area,
        quiz:Quiz,
        programingQuiz:ProgramingQuiz
        //settings: Settings,
    });
