//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

// Auth const
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_DATA = 'user_data';
export const USER_TOKEN_SET = 'user_token_set';
export const USER_UPDATE_PASSWORD = 'USER_UPDATE_PASSWORD';
export const PASSWORD_REQUIREMENTS = 'PASSWORD_REQUIREMENTS';
export const USER_UPDATE_TO_CHANGE = 'USER_UPDATE_TO_CHANGE';

//Client
export const LIST_CLIENTS = 'LIST_CLIENTS';
export const GET_CLIENT = 'GET_CLIENT';
export const ACTIVE_CLIENT = 'ACTIVE_CLIENT';
export const DELETE_CLIENT = 'DELETE_CLIENT';
export const CLIENT_STATUS_ACTIONS = 'CLIENT_STATUS_ACTIONS';
export const ADD_CLIENT = 'ADD_CLIENT';
export const CLIENT_LOADING = 'CLIENT_LOADING';
export const CLIENT_STATUS_CREATE = 'CLIENT_STATUS_CREATE';
export const CLIENT_VISIBLE = 'CLIENT_VISIBLE';
export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const LIST_CLIENTS_SELECT='LIST_CLIENTS_SELECT';

//SUPERClient
export const LIST_SUPERCLIENTS = 'LIST_SUPERCLIENTS';
export const GET_SUPERCLIENT = 'GET_SUPERCLIENT';
export const ACTIVE_SUPERCLIENT = 'ACTIVE_SUPERCLIENT';
export const DELETE_SUPERCLIENT = 'DELETE_SUPERCLIENT';
export const SUPERCLIENT_STATUS_ACTIONS = 'SUPERCLIENT_STATUS_ACTIONS';
export const ADD_SUPERCLIENT = 'ADD_SUPERCLIENT';
export const SUPERCLIENT_LOADING = 'SUPERCLIENT_LOADING';
export const SUPERCLIENT_STATUS_CREATE = 'SUPERCLIENT_STATUS_CREATE';
export const SUPERCLIENT_VISIBLE = 'SUPERCLIENT_VISIBLE';
export const VISIBLE_COMPANY = 'VISIBLE_COMPANY';
export const UPDATE_SUPERCLIENT = 'UPDATE_SUPERCLIENT';
export const AUTH_STATUS_ACTIONS='AUTH_STATUS_ACTIONS';
export const AUTH_MESSAGE='AUTH_MESSAGE';
export const LIST_SUPERCLIENTS_SELECT='LIST_SUPERCLIENTS_SELECT';
export const LIST_SUPERCLIENT_COMPANIES='LIST_SUPERCLIENT_COMPANIES';
export const ACTIVE_SUPERCLIENT_COMPANIES='ACTIVE_SUPERCLIENT_COMPANIES';
export const DELETE_SUPERCLIENT_COMPANIES='DELETE_SUPERCLIENT_COMPANIES';

//Client users
export const LIST_CLIENT_USERS = 'LIST_CLIENT_USERS';
export const CLIENT_ACCESS_VISIBLE = 'CLIENT_ACCESS_VISIBLE'
export const GET_ACCESS_CLIENT = 'GET_ACCESS_CLIENT'
export const CLIENT_ACCESS_LOADING = 'CLIENT_ACCESS_LOADING'
export const ADD_CLIENT_ACCESS = 'ADD_CLIENT_ACCESS'
export const CLIENT_ACCESS_STATUS_ACTIONS = 'CLIENT_ACCESS_STATUS_ACTIONS'
export const UPDATE_CLIENT_ACCESS = 'UPDATE_CLIENT_ACCESS'
export const ACTIVE_CLIENT_ACCESS = 'ACTIVE_CLIENT_ACCESS'
export const DELETE_CLIENT_ACCESS = 'DELETE_CLIENT_ACCESS'
export const RESET_PASSWORD__CLIENT_ACCESS = 'RESET_PASSWORD__CLIENT_ACCESS'

//Register

  export const REGISTER_LOADING='REGISTER_LOADING';
  export const REGISTER_VISIBLE='REGISTER_VISIBLE';
  export const REGISTER_STATUS_CREATE='REGISTER_STATUS_CREATE';
  export const REGISTER_STATUS_ACTIONS='REGISTER_STATUS_ACTIONS';
  export const ADD_REGISTER='ADD_REGISTER';
  export const REGISTER_USER_NAME='REGISTER_USER_NAME';
  export const REGISTER_MESSAGE='REGISTER_MESSAGE';

  //ACTIVATE

  export const ACTIVATE_LOADING='ACTIVATE_LOADING';
  export const ACTIVATE_VISIBLE='ACTIVATE_VISIBLE';
  export const ACTIVATE_STATUS_CREATE='ACTIVATE_STATUS_CREATE';
  export const ACTIVATE_STATUS_ACTIONS='ACTIVATE_STATUS_ACTIONS';
  export const ADD_ACTIVATE='ADD_ACTIVATE';
  export const ACTIVATE_MESSAGE='ACTIVATE_MESSAGE';

//Prevention Engineer

export const  SHOW_PREVENTION_ENGINEER='SHOW_PREVENTION_ENGINEER';
export const PREVENTION_ENGINEER_LOADING='PREVENTION_ENGINEER_LOADING';
export const PREVENTION_ENGINEER_VISIBLE='PREVENTION_ENGINEER_VISIBLE';
export const PREVENTION_ENGINEER_STATUS_CREATE='PREVENTION_ENGINEER_STATUS_CREATE';
export const PREVENTION_ENGINEER_STATUS_ACTIONS='PREVENTION_ENGINEER_STATUS_ACTIONS';
export const ADD_PREVENTION_ENGINEER='ADD_PREVENTION_ENGINEER';
export const LIST_PREVENTION_ENGINEER='LIST_PREVENTION_ENGINEER';
export const DELETE_PREVENTION_ENGINEER='DELETE_PREVENTION_ENGINEER';
export const GET_PREVENTION_ENGINEER ='GET_PREVENTION_ENGINEER';
export const UPDATE_PREVENTION_ENGINEER ='UPDATE_PREVENTION_ENGINEER';
export const ACTIVE_PREVENTION_ENGINEER ='ACTIVE_PREVENTION_ENGINEER';
export const DATA_CREATE_PREVENTION_ENGINEER ='DATA_CREATE_PREVENTION_ENGINEER';
export const DATA_CREATE_STATUS_PREVENTION_ENGINEER ='DATA_CREATE_STATUS_PREVENTION_ENGINEER';


//Expert

export const  SHOW_EXPERT='SHOW_EXPERT';
export const EXPERT_LOADING='EXPERT_LOADING';
export const EXPERT_VISIBLE='EXPERT_VISIBLE';
export const EXPERT_STATUS_CREATE='EXPERT_STATUS_CREATE';
export const EXPERT_STATUS_ACTIONS='EXPERT_STATUS_ACTIONS';
export const ADD_EXPERT='ADD_EXPERT';
export const LIST_EXPERT='LIST_EXPERT';
export const DELETE_EXPERT='DELETE_EXPERT';
export const GET_EXPERT='GET_EXPERT';
export const UPDATE_EXPERT='UPDATE_EXPERT';
export const ACTIVE_EXPERT='ACTIVE_EXPERT';
export const DATA_CREATE_EXPERT ='DATA_CREATE_EXPERT';
export const DATA_CREATE_STATUS_EXPERT ='DATA_CREATE_STATUS_EXPERT';


//Checklist
export const LIST_CHECKLIST='LIST_CHECKLIST';
export const ACTIVE_CHECKLIST='ACTIVE_CHECKLIST';
export const DELETE_CHECKLIST='DELETE_CHECKLIST';
export const CHECKLIST_STATUS_ACTIONS='CHECKLIST_STATUS_ACTIONS';
export const ADD_CHECKLIST='ADD_CHECKLIST';
export const CHECKLIST_LOADING='CHECKLIST_LOADING';
export const CHECKLIST_VISIBLE='CHECKLIST_VISIBLE';
export const GET_CHECKLIST='GET_CHECKLIST';
export const UPDATE_CHECKLIST='UPDATE_CHECKLIST';


//AUTODIAGNOSTIC
export const  SHOW_AUTODIAGNOSTIC='SHOW_AUTODIAGNOSTIC';
export const AUTODIAGNOSTIC_LOADING='AUTODIAGNOSTIC_LOADING';
export const AUTODIAGNOSTIC_VISIBLE='AUTODIAGNOSTIC_VISIBLE';
export const AUTODIAGNOSTIC_STATUS_CREATE='AUTODIAGNOSTIC_STATUS_CREATE';
export const AUTODIAGNOSTIC_STATUS_ACTIONS='AUTODIAGNOSTIC_STATUS_ACTIONS';
export const ADD_AUTODIAGNOSTIC='ADD_AUTODIAGNOSTIC';
export const LIST_AUTODIAGNOSTIC='LIST_AUTODIAGNOSTIC';
export const DELETE_AUTODIAGNOSTIC='DELETE_AUTODIAGNOSTIC';
export const GET_AUTODIAGNOSTIC='GET_AUTODIAGNOSTIC';
export const UPDATE_AUTODIAGNOSTIC='UPDATE_AUTODIAGNOSTIC';
export const ACTIVE_AUTODIAGNOSTIC='ACTIVE_AUTODIAGNOSTIC';
export const AUTODIAGNOSTIC_MESSAGE='AUTODIAGNOSIS_MESSAGE';
export const SHOW_LIST='SHOW_LIST';
export const LIST_DETOUR='LIST_DETOUR';


//REPORTS
export const SHOW_RESULTS='SHOW_RESULTS';
export const SHOW_EVALUATIONS='SHOW_EVALUATIONS';


//ADVISORY

export const  SHOW_ADVISORY='SHOW_ADVISORY';
export const ADVISORY_LOADING='ADVISORY_LOADING';
export const ADVISORY_VISIBLE='ADVISORY_VISIBLE';
export const ADVISORY_STATUS_CREATE='ADVISORY_STATUS_CREATE';
export const ADVISORY_STATUS_ACTIONS='ADVISORY_STATUS_ACTIONS';
export const ADD_ADVISORY='ADD_ADVISORY';
export const LIST_ADVISER='LIST_ADVISER';
export const DELETE_ADVISORY='DELETE_ADVISORY';
export const GET_ADVISORY='GET_ADVISORY';
export const UPDATE_ADVISORY='UPDATE_ADVISORY';
export const ACTIVE_ADVISORY='ACTIVE_ADVISORY';
export const RESPONSE_ADVISORY='RESPONSE_ADVISORY';
export const LIST_ADVISORIES='LIST_ADVISORIES';
export const SHOW_ADVISORY_REPORT='SHOW_ADVISORY_REPORT';
export const LIST_PROGRAMINGS='LIST_PROGRAMINGS';

export const LIST_EVALUATION='LIST_EVALUATION';
export const LIST_CRITERIA='LIST_CRITERIA';


//AVAILABILITY



export const  SHOW_AVAILABILITY='SHOW_AVAILABILITY';
export const AVAILABILITY_LOADING='AVAILABILITY_LOADING';
export const AVAILABILITY_VISIBLE='AVAILABILITY_VISIBLE';
export const AVAILABILITY_STATUS_CREATE='AVAILABILITY_STATUS_CREATE';
export const AVAILABILITY_STATUS_ACTIONS='AVAILABILITY_STATUS_ACTIONS';
export const ADD_AVAILABILITY='ADD_AVAILABILITY';
export const LIST_AVAILABILITY='LIST_AVAILABILITY';
export const DELETE_AVAILABILITY='DELETE_AVAILABILITY';
export const GET_AVAILABILITY='GET_AVAILABILITY';
export const UPDATE_AVAILABILITY='UPDATE_AVAILABILITY';
export const ACTIVE_AVAILABILITY='ACTIVE_AVAILABILITY';
export const RESPONSE_AVAILABILITY='RESPONSE_AVAILABILITY';
export const HOURS_AVAILABILITY='HOURS_AVAILABILITY';



//USER
export const USER_ACCESS_STATUS_ACTIONS='USER_ACCESS_STATUS_ACTIONS';
export const USER_RESET_PASSWORD='USER_RESET_PASSWORD';


//RECOMENDATION

export const SHOW_RECOMENDATION='SHOW_RECOMENDATION';
export const RECOMENDATION_LOADING='RECOMENDATION_LOADING';
export const RECOMENDATION_VISIBLE='RECOMENDATION_VISIBLE';
export const RECOMENDATION_STATUS_CREATE='RECOMENDATION_STATUS_CREATE';
export const RECOMENDATION_STATUS_ACTIONS='RECOMENDATION_STATUS_ACTIONS';
export const ADD_RECOMENDATION='ADD_RECOMENDATION';
export const LIST_RECOMENDATION='LIST_RECOMENDATION';
export const DELETE_RECOMENDATION='DELETE_RECOMENDATION';
export const GET_RECOMENDATION='GET_RECOMENDATION';
export const UPDATE_RECOMENDATION='UPDATE_RECOMENDATION';
export const ACTIVE_RECOMENDATION='ACTIVE_RECOMENDATION';
export const RESPONSE_RECOMENDATION='RESPONSE_RECOMENDATION';
export const HOURS_RECOMENDATION='HOURS_AVAILABILITY';
export const COMMENT_VISIBLE='COMMENT_VISIBLE';
export const GET_COMMENT='GET_COMMENT';
export const ADD_COMMENT='ADD_COMMENT';

//BRANCH_OFFICE
export const LIST_BRANCH_OFFICES = 'LIST_BRANCH_OFFICES';
export const GET_BRANCH_OFFICE = 'GET_BRANCH_OFFICE';
export const ACTIVE_BRANCH_OFFICE = 'ACTIVE_BRANCH_OFFICE';
export const DELETE_BRANCH_OFFICE = 'DELETE_BRANCH_OFFICE';
export const BRANCH_OFFICE_STATUS_ACTIONS = 'BRANCH_OFFICE_STATUS_ACTIONS';
export const ADD_BRANCH_OFFICE = 'ADD_BRANCH_OFFICE';
export const BRANCH_OFFICE_LOADING = 'BRANCH_OFFICE_LOADING';
export const BRANCH_OFFICE_STATUS_CREATE = 'BRANCH_OFFICE_STATUS_CREATE';
export const BRANCH_OFFICE_VISIBLE = 'BRANCH_OFFICE_VISIBLE';
export const UPDATE_BRANCH_OFFICE = 'UPDATE_BRANCH_OFFICE';
export const LIST_BRANCH_OFFICES_SELECT='LIST_BRANCH_OFFICES_SELECT';

//CONTACT
export const LIST_CONTACTS = 'LIST_CONTACTS';
export const GET_CONTACT = 'GET_CONTACT';
export const ACTIVE_CONTACT = 'ACTIVE_CONTACT';
export const DELETE_CONTACT = 'DELETE_CONTACT';
export const CONTACT_STATUS_ACTIONS = 'CONTACT_STATUS_ACTIONS';
export const ADD_CONTACT = 'ADD_CONTACT';
export const CONTACT_LOADING = 'CONTACT_LOADING';
export const CONTACT_STATUS_CREATE = 'CONTACT_STATUS_CREATE';
export const CONTACT_VISIBLE = 'CONTACT_VISIBLE';
export const UPDATE_CONTACT = 'UPDATE_CONTACT';
export const LIST_CONTACTS_SELECT='LIST_CONTACTS_SELECT';


//AREA
export const LIST_AREAS = 'LIST_AREAS';
export const GET_AREA = 'GET_AREA';
export const ACTIVE_AREA = 'ACTIVE_AREA';
export const DELETE_AREA = 'DELETE_AREA';
export const AREA_STATUS_ACTIONS = 'AREA_STATUS_ACTIONS';
export const ADD_AREA = 'ADD_AREA';
export const AREA_LOADING = 'AREA_LOADING';
export const AREA_STATUS_CREATE = 'AREA_STATUS_CREATE';
export const AREA_VISIBLE = 'AREA_VISIBLE';
export const UPDATE_AREA = 'UPDATE_AREA';
export const LIST_AREAS_SELECT='LIST_AREAS_SELECT';

//QUIZ
export const LIST_QUIZ='LIST_QUIZ';
export const ACTIVE_QUIZ='ACTIVE_QUIZ';
export const DELETE_QUIZ='DELETE_QUIZ';
export const QUIZ_STATUS_ACTIONS='QUIZ_STATUS_ACTIONS';
export const ADD_QUIZ='ADD_QUIZ';
export const QUIZ_LOADING='QUIZ_LOADING';
export const QUIZ_VISIBLE='QUIZ_VISIBLE';
export const GET_QUIZ='GET_QUIZ';
export const UPDATE_QUIZ='UPDATE_QUIZ';
export const LIST_QUIZ_SELECT='LIST_QUIZ_SELECT';


//PROGRAMING_QUIZ
export const LIST_PROGRAMING_QUIZ='LIST_PROGRAMING_QUIZ';
export const ACTIVE_PROGRAMING_QUIZ='ACTIVE_PROGRAMING_QUIZ';
export const DELETE_PROGRAMING_QUIZ='DELETE_PROGRAMING_QUIZ';
export const PROGRAMING_QUIZ_STATUS_ACTIONS='PROGRAMING_QUIZ_STATUS_ACTIONS';
export const ADD_PROGRAMING_QUIZ='ADD_PROGRAMING_QUIZ';
export const PROGRAMING_QUIZ_LOADING='PROGRAMING_QUIZ_LOADING';
export const PROGRAMING_QUIZ_VISIBLE='PROGRAMING_QUIZ_VISIBLE';
export const GET_PROGRAMING_QUIZ='GET_PROGRAMING_QUIZ';
export const UPDATE_PROGRAMING_QUIZ='UPDATE_PROGRAMING_QUIZ';
