import React, { useState, useEffect } from 'react';
import axios from '../util/Api'
import "../styles/register.scss"
import logo from "../logo_pacifico.png"
import 'antd/dist/antd.css'
import { connect, useSelector } from "react-redux";

import { Row, Col, Card, Input, InputNumber, Button, message } from 'antd'
import { MailOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { addRegister } from "../appRedux/actions/Register";
import InfoView from "../components/InfoView";

import { SearchOutlined } from '@ant-design/icons';

const ValidateCertificate = (props) => {

    const [data, setData] = useState({})
    const [error, setError] = useState(false)

    const [token, setToken] = useState('')



    useEffect(() => {
        let params = new URLSearchParams(props.location.search);
        setToken(params.get('token'))
    }, [])

    useEffect(() => {
        if (token != '') {
            getData()

        }

    }, [token])

    function getData() {
        axios.post(`/external/validate-certificate`, { token: token }).then(({ data }) => {
            if (data) {
                setData(data.data)

            } else {
                setError(true)
            }
        }).catch(function (error) {
            setError(true)


        });
    }

    return (
        <div class='body ' >
            <Row style={{ justifyContent: 'center' }}>
                <img width="200" src={logo} />
            </Row>


            <Row >
                <Col className='col-validate' xs={24} lg={12}>
                    <div className='card-validate' >

                        <div className="advisorCard" style={{ textAlign: "center" }}>

                            {error ?
                                <div class="title-secondary" style={{ textAlign: 'center' }} >Url incorrecta:</div>

                                :
                                <>
                                    {console.log(data)}
                                    {Object.keys(data).length > 0 ?
                                        <>
                                            <CheckCircleOutlined
                                                style={{
                                                    fontSize: "2.5rem",
                                                    color: "#0099cc",
                                                    marginTop: ".5rem",
                                                    marginBottom: ".5rem",
                                                }}
                                            />
                                            <p
                                                style={{
                                                    fontSize: "1.6rem",
                                                    fontWeight: "bolder",
                                                    color: "#4a4a4a",
                                                    marginBottom: "1rem",
                                                }}
                                            >
                                                {data.template === 'Certificado' ? 'Certificado validado' : 'Constancia validada'}
                                            </p>
                                            <Row style={{ textAlign: 'left', margin: 'auto 10px', width: '100%' }}>
                                                {(data.document).length > 7 && !(data.document).includes('0000') ?
                                                    <Col xs={24} lg={24} >
                                                        <b>Participante:</b> {data.fullName} - {data.document}
                                                    </Col>
                                                    : <Col xs={24} lg={24} >
                                                        <b>Participante:</b> {data.document}
                                                    </Col>
                                                }
                                                <br></br>
                                                {data.template === 'Certificado' && data.modality === 'CAPACITACIÓN' ?
                                                    <>
                                                        <Col xs={24} lg={24} >
                                                            <b>Calificación:</b> {data.calification}
                                                        </Col>
                                                        <br></br> </> : ''


                                                }

                                                <Col xs={24} lg={24} >
                                                    <b>Curso:</b> {data.courseName}
                                                </Col>
                                                <br></br>
                                                <Col xs={24} lg={24} >
                                                    <b>Tipo:</b> {data.type}
                                                </Col>
                                                <br></br>
                                                <Col xs={24} lg={24} >
                                                    <b>Modalidad:</b> {data.modality}
                                                </Col>
                                                <br></br>
                                                <Col xs={24} lg={24} >
                                                    <b>Carga horaria:</b> {data.hours}
                                                </Col>
                                                <br></br>
                                                <Col xs={24} lg={24} >
                                                    <b>Expositor:</b> {data.expositor}
                                                </Col>
                                                <br></br>
                                                {
                                                    (data.type === 'WEBINAR' && data.showConsulting === '1') || (data.type === 'CAPACITACIÓN') ?

                                                        <Col xs={24} lg={24} >
                                                            <b>Consultora:</b> {data.consulting}
                                                        </Col>

                                                        : <></>
                                                }
                                                <br></br>

                                            </Row>



                                        </>

                                        :

                                        <>

                                            <div class="title-secondary" style={{ textAlign: 'center' }} >Cargando ...</div>

                                        </>


                                    }
                                </>


                            }

                        </div>

                    </div>
                </Col>
            </Row>



            <InfoView />
        </div >
    );

}



export default connect(null, { addRegister })(ValidateCertificate);

