import { LIST_PROGRAMING_QUIZ, ACTIVE_PROGRAMING_QUIZ, DELETE_PROGRAMING_QUIZ, 
  PROGRAMING_QUIZ_STATUS_ACTIONS,
  PROGRAMING_QUIZ_LOADING,
  ADD_PROGRAMING_QUIZ,
  PROGRAMING_QUIZ_VISIBLE,
  GET_PROGRAMING_QUIZ,
  UPDATE_PROGRAMING_QUIZ,

 } from "../ActionTypes";

const INIT_STATE = {
  programingQuizList: [],
  programingQuizObject: [],
  programingQuizStatusActions: 0,
  loading:false,
  visible:false,
  programingQuizStatusCreate: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case LIST_PROGRAMING_QUIZ: {
      return {
        ...state,
        programingQuizList: action.payload,
      };
    }

   

    case GET_PROGRAMING_QUIZ: {
      return {
        ...state,
        programingQuizObject: action.payload,
      };
    }

    case ACTIVE_PROGRAMING_QUIZ: {
        return {
            ...state
        }
    }   

    case DELETE_PROGRAMING_QUIZ: {
        return {
            ...state
        }
    }

    case PROGRAMING_QUIZ_STATUS_ACTIONS: {
      return {
          ...state,
          programingQuizStatusActions:action.payload
      }
    }

  case PROGRAMING_QUIZ_LOADING: {
      return {
      ...state,
      loading: action.payload,
      }
   }

   case ADD_PROGRAMING_QUIZ: {
      return {
          ...state
      }
    }

    case PROGRAMING_QUIZ_VISIBLE: {
      return {
      ...state,
      visible: action.payload,
      }
    }

    case UPDATE_PROGRAMING_QUIZ: {
      return {
          ...state
      }
  }

    default:
      return state;
  }
}
