import {LIST_BRANCH_OFFICES,BRANCH_OFFICE_STATUS_CREATE,UPDATE_BRANCH_OFFICE,GET_BRANCH_OFFICE,ACTIVE_BRANCH_OFFICE,
   DELETE_BRANCH_OFFICE,BRANCH_OFFICE_STATUS_ACTIONS,BRANCH_OFFICE_LOADING, ADD_BRANCH_OFFICE, BRANCH_OFFICE_VISIBLE,LIST_BRANCH_OFFICES_SELECT} from "../ActionTypes";

const INIT_STATE = {
  branchOfficesList: [],
  branchOfficeListSelect:[],
  branchOfficeObject: [],
  branchOfficeStatusActions: 0,
  loading:false,
  visible:false,
  branchOfficeStatusCreate: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case LIST_BRANCH_OFFICES: {
      return {
        ...state,
        branchOfficesList: action.payload,
      };
    }

    case LIST_BRANCH_OFFICES_SELECT: {
      return {
        ...state,
        branchOfficeListSelect: action.payload,
      };
    }

    case BRANCH_OFFICE_STATUS_CREATE: {
      return {
          ...state,
          branchOfficeStatusCreate:action.payload
      }
    }

    case GET_BRANCH_OFFICE: {
      return {
        ...state,
        branchOfficeObject: action.payload,
      };
    }

    case ACTIVE_BRANCH_OFFICE: {
        return {
            ...state
        }
    }   

    case DELETE_BRANCH_OFFICE: {
        return {
            ...state
        }
    }

    case BRANCH_OFFICE_STATUS_ACTIONS: {
      return {
          ...state,
          branchOfficeStatusActions:action.payload
      }
    }

    case BRANCH_OFFICE_LOADING: {
      return {
      ...state,
      loading: action.payload,
      }
   }

   case ADD_BRANCH_OFFICE: {
      return {
          ...state
      }
    }

    case BRANCH_OFFICE_VISIBLE: {
      return {
      ...state,
      visible: action.payload,
      }
    }

    case UPDATE_BRANCH_OFFICE: {
      return {
          ...state
      }
  }

    default:
      return state;
  }
}
