import {
    SHOW_EXPERT,
    EXPERT_LOADING,
    EXPERT_VISIBLE,
    EXPERT_STATUS_CREATE,
    EXPERT_STATUS_ACTIONS,
    ADD_EXPERT,
    LIST_EXPERT,
    DELETE_EXPERT,
    GET_EXPERT,
    UPDATE_EXPERT,
    ACTIVE_EXPERT,
    DATA_CREATE_EXPERT,
    DATA_CREATE_STATUS_EXPERT

} from "../ActionTypes";

const INIT_STATE = {
    expertList: [],
    expertListSelect:[],
    meta:{
        totalData:0,
        pageSize:0,
        current:0,
        totalPages:0
    },
    expertObject: [],
    expertStatusCreate: 0,
    expertStatusActions: 0,
    loading:false,
    visible:false,
    showModalSuccess:false,
    dataCreate:[]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
  
        case SHOW_EXPERT: {
            return {
                ...state,
                expertList: action.payload,
            }
        }
        case ACTIVE_EXPERT: {
            return {
                ...state
            }
        }


        case EXPERT_STATUS_ACTIONS: {
            return {
                ...state,
                expertStatusActions:action.payload
            }
        }
        
        case EXPERT_STATUS_CREATE: {
            return {
                ...state,
                expertStatusCreate:action.payload
            }
        }

        case EXPERT_LOADING: {
            return {
            ...state,
            loading: action.payload,
            }
        }
        case  EXPERT_VISIBLE : {
            return {
            ...state,
            visible: action.payload,
            }
        }

        case ADD_EXPERT: {
            return {
                ...state
            }
        }
      
        case GET_EXPERT: {
            return {
                ...state,
                expertObject : action.payload
            }
        }

        case LIST_EXPERT: {
            return {
                ...state,
                expertListSelect: action.payload,
            }
        }      

        case DELETE_EXPERT: {
            return {
                ...state
            }
        }


        case UPDATE_EXPERT: {
            return {
                ...state
            }
        }

        case DATA_CREATE_EXPERT: {
            return {
                ...state,
                dataCreate: action.payload,
            }
        } 
        case DATA_CREATE_STATUS_EXPERT: {
            return {
                ...state,
                showModalSuccess: action.payload,
            }
        } 

      default:
        return state;
    }
  }
  