import { LIST_CHECKLIST, ACTIVE_CHECKLIST, DELETE_CHECKLIST, 
  CHECKLIST_STATUS_ACTIONS,
  CHECKLIST_LOADING,
  ADD_CHECKLIST,
  CHECKLIST_VISIBLE,
  GET_CHECKLIST,
  UPDATE_CHECKLIST,

 } from "../ActionTypes";

const INIT_STATE = {
  checklistList: [],
  checklistObject: [],
  checklistStatusActions: 0,
  loading:false,
  visible:false,
  checklistStatusCreate: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case LIST_CHECKLIST: {
      return {
        ...state,
        checklistList: action.payload,
      };
    }

   

    case GET_CHECKLIST: {
      return {
        ...state,
        CHECKLISTObject: action.payload,
      };
    }

    case ACTIVE_CHECKLIST: {
        return {
            ...state
        }
    }   

    case DELETE_CHECKLIST: {
        return {
            ...state
        }
    }

    case CHECKLIST_STATUS_ACTIONS: {
      return {
          ...state,
          checklistStatusActions:action.payload
      }
    }

  case CHECKLIST_LOADING: {
      return {
      ...state,
      loading: action.payload,
      }
   }

   case ADD_CHECKLIST: {
      return {
          ...state
      }
    }

    case CHECKLIST_VISIBLE: {
      return {
      ...state,
      visible: action.payload,
      }
    }

    case UPDATE_CHECKLIST: {
      return {
          ...state
      }
  }

    default:
      return state;
  }
}
