import React from "react";
import { Col, Layout, Menu, Row } from "antd";
import { useRouteMatch } from "react-router-dom";
import NavBar from "../../components/NavBar";
import "../../styles/kernel.scss";
import "../../styles/home.scss";
import { useDispatch, useSelector } from "react-redux";

import App from "../../routes/index";

const { Header, Content, Footer } = Layout;

const MainApp = () => {
    const match = useRouteMatch();
    const dispatch = useDispatch();
    const authUser = useSelector(({ auth }) => auth.authUser);
    let dateNow = new Date();
    let  year=dateNow.getUTCFullYear()

    let menuList = [];
    if (authUser) {
        switch (authUser.idGroup) {
            case 0:
                menuList = [
                    { menu: "Gestión de Clientes", link: "/base/client/list" },
                    {
                        menu: "Gestión de Especialistas",
                        link: "/base/expert/list",
                    },
                    {
                        menu: "Gestión de Consultoras",
                        link: "/base/consultingCompany/list",
                    },
                    {
                        menu: "Gestión de Ing. Prevención",
                        link: "/base/engineer/list",
                    },
                    { menu: "Evaluaciones de Clientes", link: "/evaluation" },
                    {
                        menu: "Gestión de Checklist",
                        link: "/base/checklist/list",
                    },
                    {
                        menu: "Recomendaciones",
                        link: "/base/recomendation/list",
                    },
                    {
                        menu: "Superclientes",
                        link: "/base/superclient/list",
                    },
                    {
                        menu: "Gestión de encuestas",
                        link: "/base/quiz/list",
                    },
                    {
                        menu: "Gestión de Cursos",
                        link: "/base/courses/list",
                    },
                    // {
                    //     menu: "Coordinadores de consultora",
                    //     link: "/base/coordinator/list",
                    // },

                ];
                break;
            case 1:
                menuList = [
                    { menu: "Gestión de Clientes", link: "/base/client/list" },
                    {
                        menu: "Gestión de Especialistas",
                        link: "/base/expert/list",
                    },
                    {
                        menu: "Gestión de Consultoras",
                        link: "/base/consultingCompany/list",
                    },
                    {
                        menu: "Gestión de Ing. Prevención",
                        link: "/base/engineer/list",
                    },
                    { menu: "Evaluaciones de Clientes", link: "/evaluation" },
                    {
                        menu: "Gestión de Checklist",
                        link: "/base/checklist/list",
                    },
                    {
                        menu: "Gestión de Cursos",
                        link: "/base/courses/list",
                    },
                    // {
                    //     menu: "Coordinadores de consultora",
                    //     link: "/base/coordinator/list",
                    // },
                ];
                break;
            case 2:
                menuList = [
                    { menu: "Mís Asesorías", link: "/base/advisory/list" },
                    { menu: "Evaluaciones de Clientes", link: "/evaluation" },

                ];
                break;
            case 3:
                authUser.host == '1' ? menuList = [
                    { menu: "Autoevaluación", link: "/base/autodiagnostic" },
                    // { menu: "Mís Asesorías", link: "/advisory/list" },
                    { menu: "Reportes", link: "/reports" },
                    { menu: "Sedes y contactos", link: "/base/campus-contact" },
                    { menu: "Evaluación para sedes", link: "/base/autodiagnostic/program" },


                ] : menuList = [
                    { menu: "Autoevaluación", link: "/base/autodiagnostic" },
                    // { menu: "Mís Asesorías", link: "/advisory/list" },
                    { menu: "Reportes", link: "/reports" },

                ];
                break;
            case 4:
                menuList = [
                    { menu: "Gestión de Clientes", link: "/base/client/list" },
                    { menu: "Mís Asesorías", link: "/base/advisory/list" },
                    { menu: "Evaluaciones de Clientes", link: "/evaluation" },
                    {
                        menu: "Gestión de Checklist",
                        link: "/base/checklist/list",
                    },
                    {
                        menu: "Gestión de Especialistas",
                        link: "/base/expert/list",
                    },
                    {
                        menu: "Gestión de Consultoras",
                        link: "/base/consultingCompany/list",
                    },
                    {
                        menu: "Gestión de Ing. Prevención",
                        link: "/base/engineer/list",
                    },
                    {
                        menu: "Recomendaciones",
                        link: "/base/recomendation/list",
                    },
                ];
                break;

            case 5:
                menuList = [
                    { menu: "Resultados de evaluación", link: "/reports/superclient" },

                ];
                break;

            case 6:

                menuList = [
                    {
                        menu: "Gestión de Especialistas",
                        link: "/base/expert/list",
                    },
                    {
                        menu: "Gestión de Cursos",
                        link: "/base/courses/list",
                    },
                    {
                        menu: "Coordinadores de consultora",
                        link: "/base/coordinator/list",
                    },
                ];
                break;

        }
    }

    return (
        <Layout>
            <NavBar items={menuList} />
            <Content className="site-layout" >
                <App match={match} />
            </Content>
            <Footer className="footer">
                <Row style={{ width: '100%' }}>
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <p className="user">{`${JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).group : ''} / ${JSON.parse(localStorage.getItem('user')) ? JSON.parse(localStorage.getItem('user')).fullName : ''}`}</p>
                    </Col>
                   
                    <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <p className="create">Pacifico Seguros ©{year} Creado por <a style={{}} target="_blank" href="https://iteprevengo.com/">ITEPREVENGO</a></p>
                    </Col>
                </Row>
            </Footer>
        </Layout>
    );
};
export default MainApp;
