import {
    SHOW_RESULTS,SHOW_EVALUATIONS

} from "../ActionTypes";

const INIT_STATE = {
   resultList: [],
   evaluationList:[],
    meta:{
        totalData:0,
        pageSize:0,
        current:0,
        totalPages:0
    },
  
    loading:false,
    visible:false
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
  
        case SHOW_RESULTS: {
            return {
                ...state,
                resultList: action.payload,
            }
        }

        case SHOW_EVALUATIONS: {
            return {
                ...state,
                evaluationList: action.payload,
            }
        }
       
       

      default:
        return state;
    }
  }
  