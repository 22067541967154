import {
    SHOW_AUTODIAGNOSTIC,
    AUTODIAGNOSTIC_LOADING,
    AUTODIAGNOSTIC_VISIBLE,
    AUTODIAGNOSTIC_STATUS_CREATE,
    AUTODIAGNOSTIC_STATUS_ACTIONS,
    ADD_AUTODIAGNOSTIC,
    LIST_AUTODIAGNOSTIC,
    DELETE_AUTODIAGNOSTIC,
    GET_AUTODIAGNOSTIC,
    UPDATE_AUTODIAGNOSTIC,
    ACTIVE_AUTODIAGNOSTIC,
    AUTODIAGNOSTIC_MESSAGE,
    SHOW_LIST,
    LIST_DETOUR


} from "../ActionTypes";

const INIT_STATE = {
    autodiagnosticList: [],
    detourList:[],
    autodiagnosticListSelect: [],
    meta: {
        totalData: 0,
        pageSize: 0,
        current: 0,
        totalPages: 0
    },
    autodiagnosticObject: [],
    autodiagnosticStatusCreate: 0,
    autodiagnosticStatusActions: 0,
    loading: false,
    visible: false,
    autodiagnostic_message:'',
    show_list:0
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case SHOW_AUTODIAGNOSTIC: {
            return {
                ...state,
                autodiagnosticList: action.payload.data,
                meta: {
                    totalData: action.payload.meta.total,
                    pageSize: action.payload.meta.per_page,
                    current: action.payload.meta.current_page,
                    totalPages: action.payload.meta.last_page
                }
            }
        }
        case ACTIVE_AUTODIAGNOSTIC: {
            return {
                ...state
            }
        }


        case AUTODIAGNOSTIC_STATUS_ACTIONS: {
            return {
                ...state,
                autodiagnosticStatusActions: action.payload
            }
        }

        case AUTODIAGNOSTIC_STATUS_CREATE: {
            return {
                ...state,
                autodiagnosticStatusCreate: action.payload
            }
        }

        case AUTODIAGNOSTIC_LOADING: {
            return {
                ...state,
                loading: action.payload,
            }
        }
        case AUTODIAGNOSTIC_VISIBLE: {
            return {
                ...state,
                visible: action.payload,
            }
        }

        case ADD_AUTODIAGNOSTIC: {
            return {
                ...state
            }
        }

        case GET_AUTODIAGNOSTIC: {
            return {
                ...state,
                autodiagnosticObject: action.payload
            }
        }

        case LIST_AUTODIAGNOSTIC: {
            return {
                ...state,
                autodiagnosticList: action.payload,
            }
        }

        case LIST_DETOUR: {
            return {
                ...state,
                detourList: action.payload,
            }
        }

        case DELETE_AUTODIAGNOSTIC: {
            return {
                ...state
            }
        }

        case AUTODIAGNOSTIC_MESSAGE: {
            return {
                autodiagnostic_message: action.payload

            }
        }

        case SHOW_LIST: {
            return {
                show_list: action.payload

            }
        }


        case UPDATE_AUTODIAGNOSTIC: {
            return {
                ...state
            }
        }

       
      default:
            return state;
    }
}
