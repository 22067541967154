import {

  ACTIVATE_LOADING,
  ACTIVATE_VISIBLE,
  ACTIVATE_STATUS_CREATE,
  ACTIVATE_STATUS_ACTIONS,
  ADD_ACTIVATE,
  ACTIVATE_MESSAGE


} from "../ActionTypes";

const INIT_STATE = {
  activateList: [],
  activateListSelect:[],
  meta:{
      totalData:0,
      pageSize:0,
      current:0,
      totalPages:0
  },
  activateObject: [],
  activateStatusCreate: 0,
  activateStatusActions: 0,
  loading:false,
  visible:false,
  activate_message:'Cargando',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

       
      case ACTIVATE_STATUS_ACTIONS: {
          return {
              ...state,
              activateStatusActions:action.payload
          }
      }
      
      case ACTIVATE_STATUS_CREATE: {
          return {
              ...state,
              activateStatusCreate:action.payload
          }
      }

      case ACTIVATE_LOADING: {
          return {
          ...state,
          loading: action.payload,
          }
      }
      case ACTIVATE_VISIBLE: {
          return {
          ...state,
          visible: action.payload,
          }
      }

      case ADD_ACTIVATE: {
          return {
              ...state
          }
      }

      case ACTIVATE_MESSAGE: {
        return {
            activate_message:action.payload

        }
    }

  

      

    default:
      return state;
  }
}
