import {LIST_CLIENTS,CLIENT_STATUS_CREATE,UPDATE_CLIENT,GET_CLIENT,ACTIVE_CLIENT,
   DELETE_CLIENT,CLIENT_STATUS_ACTIONS,CLIENT_LOADING, ADD_CLIENT, CLIENT_VISIBLE,LIST_CLIENTS_SELECT} from "../ActionTypes";

const INIT_STATE = {
  clientsList: [],
  clientListSelect:[],
  clientObject: [],
  clientStatusActions: 0,
  loading:false,
  visible:false,
  clientStatusCreate: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case LIST_CLIENTS: {
      return {
        ...state,
        clientsList: action.payload,
      };
    }

    case LIST_CLIENTS_SELECT: {
      return {
        ...state,
        clientListSelect: action.payload,
      };
    }

    case CLIENT_STATUS_CREATE: {
      return {
          ...state,
          clientStatusCreate:action.payload
      }
    }

    case GET_CLIENT: {
      return {
        ...state,
        clientObject: action.payload,
      };
    }

    case ACTIVE_CLIENT: {
        return {
            ...state
        }
    }   

    case DELETE_CLIENT: {
        return {
            ...state
        }
    }

    case CLIENT_STATUS_ACTIONS: {
      return {
          ...state,
          clientStatusActions:action.payload
      }
    }

    case CLIENT_LOADING: {
      return {
      ...state,
      loading: action.payload,
      }
   }

   case ADD_CLIENT: {
      return {
          ...state
      }
    }

    case CLIENT_VISIBLE: {
      return {
      ...state,
      visible: action.payload,
      }
    }

    case UPDATE_CLIENT: {
      return {
          ...state
      }
  }

    default:
      return state;
  }
}
