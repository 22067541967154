import {
    SHOW_PREVENTION_ENGINEER,
    PREVENTION_ENGINEER_LOADING,
    PREVENTION_ENGINEER_VISIBLE,
    PREVENTION_ENGINEER_STATUS_CREATE,
    PREVENTION_ENGINEER_STATUS_ACTIONS,
    ADD_PREVENTION_ENGINEER,
    LIST_PREVENTION_ENGINEER,
    DELETE_PREVENTION_ENGINEER,
    GET_PREVENTION_ENGINEER,
    UPDATE_PREVENTION_ENGINEER,
    ACTIVE_PREVENTION_ENGINEER,
    DATA_CREATE_PREVENTION_ENGINEER,
    DATA_CREATE_STATUS_PREVENTION_ENGINEER

} from "../ActionTypes";

const INIT_STATE = {
    preventionEngineerList: [],
    preventionEngineerListSelect:[],
    meta:{
        totalData:0,
        pageSize:0,
        current:0,
        totalPages:0
    },
    preventionEngineerObject: [],
    preventionEngineerStatusCreate: 0,
    preventionEngineerStatusActions: 0,
    loading:false,
    visible:false,
    showModalSuccess:false,
    dataCreate:[]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
  
        case SHOW_PREVENTION_ENGINEER: {
            return {
                ...state,
                preventionEngineerList: action.payload
            }
        }
        case ACTIVE_PREVENTION_ENGINEER: {
            return {
                ...state
            }
        }


        case PREVENTION_ENGINEER_STATUS_ACTIONS: {
            return {
                ...state,
                preventionEngineerStatusActions:action.payload
            }
        }
        
        case PREVENTION_ENGINEER_STATUS_CREATE: {
            return {
                ...state,
                preventionEngineerStatusCreate:action.payload
            }
        }

        case PREVENTION_ENGINEER_LOADING: {
            return {
            ...state,
            loading: action.payload,
            }
        }
        case  PREVENTION_ENGINEER_VISIBLE : {
            return {
            ...state,
            visible: action.payload,
            }
        }

        case ADD_PREVENTION_ENGINEER: {
            return {
                ...state
            }
        }
      
        case GET_PREVENTION_ENGINEER: {
            return {
                ...state,
                preventionEngineerObject : action.payload
            }
        }

        case LIST_PREVENTION_ENGINEER: {
            return {
                ...state,
                preventionEngineerListSelect: action.payload,
            }
        }      

        case DELETE_PREVENTION_ENGINEER: {
            return {
                ...state
            }
        }


        case UPDATE_PREVENTION_ENGINEER: {
            return {
                ...state
            }
        }
        case DATA_CREATE_PREVENTION_ENGINEER: {
            return {
                ...state,
                dataCreate: action.payload,
            }
        } 
        case DATA_CREATE_STATUS_PREVENTION_ENGINEER: {
            return {
                ...state,
                showModalSuccess: action.payload,
            }
        } 
      default:
        return state;
    }
  }
  