import { LIST_QUIZ,
  LIST_QUIZ_SELECT,
   ACTIVE_QUIZ, DELETE_QUIZ, 
  QUIZ_STATUS_ACTIONS,
  QUIZ_LOADING,
  ADD_QUIZ,
  QUIZ_VISIBLE,
  GET_QUIZ,
  UPDATE_QUIZ,

 } from "../ActionTypes";

const INIT_STATE = {
  quizList: [],
  quizListSelect: [],

  quizObject: [],
  quizStatusActions: 0,
  loading:false,
  visible:false,
  quizStatusCreate: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case LIST_QUIZ: {
      return {
        ...state,
        quizList: action.payload,
      };
    }
    case LIST_QUIZ_SELECT: {
      return {
        ...state,
        quizListSelect: action.payload,
      };
    }
    case GET_QUIZ: {
      return {
        ...state,
        quizObject: action.payload,
      };
    }

    case ACTIVE_QUIZ: {
        return {
            ...state
        }
    }   

    case DELETE_QUIZ: {
        return {
            ...state
        }
    }

    case QUIZ_STATUS_ACTIONS: {
      return {
          ...state,
          quizStatusActions:action.payload
      }
    }

  case QUIZ_LOADING: {
      return {
      ...state,
      loading: action.payload,
      }
   }

   case ADD_QUIZ: {
      return {
          ...state
      }
    }

    case QUIZ_VISIBLE: {
      return {
      ...state,
      visible: action.payload,
      }
    }

    case UPDATE_QUIZ: {
      return {
          ...state
      }
  }

    default:
      return state;
  }
}
