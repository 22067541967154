import React, { useState, useEffect } from 'react';
import axios from '../util/Api'
import "../styles/register.scss"
import logo from "../logo_pacifico.png"
import 'antd/dist/antd.css'
import { connect, useSelector } from "react-redux";

import { Row, Col, Card, Input, InputNumber, Button, message } from 'antd'
import { MailOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { addRegister } from "../appRedux/actions/Register";
import InfoView from "../components/InfoView";

import { SearchOutlined } from '@ant-design/icons';

const Register = (props) => {
    const [showRecaptcha, setShowRecaptcha] = useState(false)

    const [ruc, setRuc] = useState('')
    const [businessName, setBusinessName] = useState('')
    const [address, setAddress] = useState('')
    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [campus, setCampus] = useState('')
    const [phone, setPhone] = useState('')
    let [show, setShow] = useState('1')
    let [showruc, setShowruc] = useState(false)
    const [rucLoading, setRucloading] = useState(false)

    const register_loading = useSelector(store => store.register.loading)
    const registerStatusActions = useSelector(store => store.register.registerStatusActions)
    const register_message = useSelector(store => store.register.register_message)
    const [tokenRecaptcha, setTokenRecaptcha] = useState('')

    const userName = useSelector(store => store.register.userName)
    let RegexEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,6})+$/;
    let RegexNumber = /^([0-9])*$/;

    function handleChangeRuc(e) {
        console.log(e)
        setRuc(e.target.value.replace(/\D/, ''))
    }
    function handleChangeBusinessName(event) {
        setBusinessName(event.target.value)

    }
    function handleChangeAddress(event) {
        setAddress(event.target.value)

    }
    function handleChangeFullName(event) {
        setFullName(event.target.value)

    }

    function handleChangeEmail(event) {
        setEmail(event.target.value)

    }

    function handleChangeCampus(event) {
        setCampus(event.target.value)
    }
    function handleChangePhone(e) {
        setPhone(e.target.value.replace(/\D/, ''))

    }

    function handleChangeCampus(event) {
        setCampus(event.target.value)

    }


    function handleOnSave() {


        if (ruc == '') {
            message.error('Ingrese un RUC')
            return false;
        }
        if (!RegexNumber.test(ruc)) {

            message.error("El RUC solo debe contener numeros.");
            return false
        }

        if (ruc.length != 11) {
            message.error('El RUC debe tener 11 caracteres')
            return false;
        }
        if (campus == '') {
            message.error('Ingrese nombre de sede')
            return false;
        }
        if (businessName == '') {
            message.error('Ingrese razón social')
            return false;
        }
        if (address == '') {
            message.error('Ingrese un dirección')
            return false;
        }
        if (email == '') {
            message.error('Ingrese un correo')
            return false;
        }

        if (!RegexEmail.test(email)) {

            message.error("La dirección de email es incorrecta.");
            return false
        }

        if (fullName == '') {
            message.error('Ingrese nombre y apellidos del conacto')
            return false;
        }
        if (phone == '') {
            message.error('Ingrese un número de celular')
            return false;
        }

        if (!RegexNumber.test(phone)) {

            message.error("El campo celular solo debe contener numeros.");
            return false
        }

        if (phone.length != 9) {
            message.error('El celular debe tener 9 caracteres')
            return false;
        }

        if (showRecaptcha) {
            handleLoaded()
        }

        let register = {
            ruc: ruc, businessName: businessName, address: address, email: email, phone: phone, fullName: fullName, campus: campus, token: tokenRecaptcha
        }



        props.addRegister(register);
    }

    function handleOnSearchRuc() {
        if (ruc == '') {
            message.error('Ingrese un RUC')
            return false;
        }
        let register = {
            ruc: ruc
        }

        let config = {
            headers: {
                'X-Requested-With': 'XMLHttpRequest'
            }
        }
        setRucloading(true)
        axios.post('/external/clients/searchruc',
            register,
            config
        ).then(({ data, status }) => {
            if (data) {
                setAddress(data.data.address)
                setBusinessName(data.data.businessName)
                setShowruc(true)
                setRucloading(false)
            }
        }).catch(function (error) {
            setShowruc(false)
            setAddress('')
            setBusinessName('')
            setRucloading(false)
            if (error.response) {
                message.error(error.response.data.message)

            } else if (error.request) {
                message.error('Error de red')
            } else {
                message.error('Error de red')
                // anything else
            }

        });
    }

    function redirectLogin() {
        var obj = document.getElementById('script')
        if (obj) {
            obj.remove();

        }

        return window.location = '/login';

    }

    useEffect(() => {

        if (registerStatusActions == 0) {
            setShow('1')

        } else
            if (registerStatusActions == 201) {
                setShow('2')

            }


    }, [registerStatusActions]);

    const handleLoaded = _ => {
        window.grecaptcha.ready(_ => {
            window.grecaptcha
                .execute("6Led0P0UAAAAAEyyrKlkd3zrZT3GnimnbOznbGCY", { action: "homepage" })
                .then(token => {
                    console.log(token)
                    setTokenRecaptcha(token)
                })
        })
    }

    useEffect(() => {
        if (showRecaptcha) {
            const script = document.createElement("script")
            script.id = 'script'
            script.src = "https://www.google.com/recaptcha/api.js?render=6Led0P0UAAAAAEyyrKlkd3zrZT3GnimnbOznbGCY"
            script.addEventListener("load", handleLoaded)
            document.body.appendChild(script)
        }
    }, [showRecaptcha])

    useEffect(() => {
        recaptcha()


    }, [])
    const recaptcha = () => {
        axios.get('external/recaptcha',
            {
                headers: {
                    "X-Requested-With": "XMLHttpRequest"
                }
            }
        ).then(({ data }) => {

            setShowRecaptcha(data.message)


        }).catch(function (error) {
            setShowRecaptcha(false)
        });
    }

    return (
        <div class='body ' >
            <Row style={{ justifyContent: 'center' }}>
                <img width="200" src={logo} />
            </Row>
            {show == '1' ?
                (
                    <Row style={{ justifyContent: 'center' }} >
                        <Card title='' className='card' >
                            <div class="title-primary" style={{ justifyContent: 'center' }} >Generación de usuario  </div>
                            <br></br>
                            <div class="title-secondary" >Datos de la empresa  </div>

                            <Row gutter={16} >
                                <Col className="gutter-row" xs={24} lg={12} >
                                    <Input type='text' maxLength={11} className="password_input" value={ruc} onChange={handleChangeRuc} placeholder='RUC' />

                                </Col>
                                <Col className="gutter-row buttonSearchRuc" xs={24} lg={6} >
                                    <Button size="" type="danger" onClick={handleOnSearchRuc} loading={rucLoading} >
                                        <SearchOutlined />
                                        Validar
                                        </Button>
                                </Col>


                            </Row>
                            <Row gutter={16} >
                                <Col className="gutter-row" xs={24} lg={12} >
                                    <Input className="password_input" maxLength={250} value={businessName} readOnly={showruc} style={{ background: showruc ? '#ececec' : '' }} onChange={handleChangeBusinessName} placeholder='Razón social' />

                                </Col>


                                <Col className="gutter-row" xs={24} lg={12} >
                                    <Input className="password_input" maxLength={250} value={address} onChange={handleChangeAddress} placeholder='Dirección' />

                                </Col>
                            </Row>
                            <Row>
                                <Col className="gutter-row" xs={24} lg={24} >
                                    <Input className="password_input" maxLength={200} value={campus} onChange={handleChangeCampus} placeholder='Sede' />

                                </Col>
                            </Row>
                            <br></br>
                            <div class="title-secondary" >Datos del contacto  </div>


                            <Row gutter={16}>

                                <Col className="gutter-row" xs={24} lg={8} >
                                    <Input className="password_input" maxLength={250} value={fullName} onChange={handleChangeFullName} placeholder='Nombres y apellidos' />

                                </Col>
                                <Col className="gutter-row" xs={24} lg={8} >
                                    <Input type='text' maxLength={9} className="password_input" value={phone} onChange={handleChangePhone} placeholder='Celular' />

                                </Col>
                                <Col className="gutter-row" xs={24} lg={8} >
                                    <Input className="password_input" maxLength={200} value={email} onChange={handleChangeEmail} placeholder='Correo' />

                                </Col>
                            </Row>
                            <br></br>
                            <Row style={{ justifyContent: 'center' }}>


                                <Button onClick={handleOnSave} loading={register_loading} className='btn-success'>Registrarse</Button>



                            </Row>
                            <Row style={{ justifyContent: 'center' }}>
                                <p style={{ justifyContent: 'center' }}>¿Ya tienes tu cuenta?&nbsp; </p> <a onClick={redirectLogin}> Inicia sesión </a>
                            </Row>

                        </Card>
                    </Row>
                ) : (

                    <Row >
                        <Col style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }} xs={18}>
                            <Card className='card'>

                                <div className="advisorCard" style={{ textAlign: "center" }}>
                                    <CheckCircleOutlined
                                        style={{
                                            fontSize: "2.5rem",
                                            color: "#0099cc",
                                            marginTop: ".5rem",
                                            marginBottom: ".5rem",
                                        }}
                                    />
                                    <p
                                        style={{
                                            fontSize: "1.6rem",
                                            fontWeight: "bolder",
                                            color: "#4a4a4a",
                                            marginBottom: "1rem",
                                        }}
                                    >
                                        ¡Registro exitoso!
                    </p>
                                    <br></br>
                                    <div class="title-secondary" style={{ textAlign: 'center' }} >Se han generado tus credencialesde acceso, su usuario es:</div>
                                    <br></br>
                                    <Row  >
                                        <Col xs={24} lg={24} >
                                            <Input style={{ borderRadius: '20px', margin: '5px', textAlign: 'center' }} size="" value={userName} disabled />

                                        </Col>
                                        <br></br>

                                    </Row>

                                    <p
                                        style={{

                                            fontSize: "0.9rem",
                                            marginTop: "1rem",
                                            marginBottom: "1rem",
                                        }}
                                    >
                                        <MailOutlined />  Se te ha enviado un correo con un enlace de activación de tu cuenta, luego de ello ya podrá iniciar sesión.
                    </p>

                                </div>
                            </Card>
                        </Col>
                    </Row>


                )
            }

            <InfoView />
        </div >
    );

}



export default connect(null, { addRegister })(Register);

